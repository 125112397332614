import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import BlankButton from '../common/blank-button';
import Loader from '../common/loader';

import '../../assets/scss/modules/_vehicle-filter.scss'


class BrowseFilter extends Component {
	constructor() {
		super();
		this.state = {
			isLoading: false
		}
	}

	// componentDidMount(){
	// 	if(this.props.pathname == '/'){
	// 		this.changeFilterHeight('405px');
	// 	}
	// }

	// componentDidUpdate(){
	// 	if(this.props.pathname == '/'){
	// 		this.changeFilterHeight('405px');
	// 	}else{
	// 		this.changeFilterHeight('170px');
	// 	}
	// }

	// changeFilterHeight(height){
	// 	const vertMenuWide = document.getElementsByClassName('vertMenuWide')
	// 	Array.prototype.slice.call(vertMenuWide).map((div) => {
	// 		div.style.height = height;
	// 	})
	// }

	handleInputChange = (event) => {
		this.props.filterChange(event.target.value);
	}

	render() {
		const { name, filterKey } = this.props;
		const { isLoading } = this.state;

		if (isLoading) {
			return (
				<Loader />
			)
		}

		return (
			<div className="browse-filter">
				<div className="browse-filter-web">
					<div className="browse-filter__head">
						<div className="browse-filter__actions">
							<BlankButton handleBtnClick={this.props.handleBackButtonClick}>
								<FontAwesomeIcon icon={faArrowLeft} />
							</BlankButton>
						</div>
					</div>

					<div className="browse-filter__options">
						<div className={"extDivWide"}>
							<a className='tit'>{this.props.name}:</a>
							<div className={"vertMenuWide"}>
								{
									this.props.options.map((option, index) => {
										return (
											<div className="browse-filter__option" key={option.id || option + index}>
												<input
													className='option-input'
													type="radio"
													name={name}
													id={filterKey + '-' + option.name}
													value={option.name || option}
													onClick={this.handleInputChange}
												/>
												<label
													htmlFor={filterKey + '-' + option.name}
													className={option !== this.props.filters[filterKey] ? "option-label" : "browse-filter__option-selected"}
												>
													{option.name || option}
												</label>
											</div>
										)
									})
								}
							</div>
						</div>
					</div>
				</div>
				<div className="browse-filter-mobile">
					<div className="browse-filter__head">
						<h2 className="browse-filter__title">Select <span>{name}</span></h2>
						<div className="browse-filter__actions">
							<BlankButton handleBtnClick={this.props.handleBackButtonClick}>
								<FontAwesomeIcon icon={faArrowLeft} />
							</BlankButton>
						</div>
					</div>

					<div className="browse-filter__options">
						{
							this.props.options.map((option, index) => {
								return (
									<div className="browse-filter__option" key={option.id || option + index}>
										<input
											type="radio"
											name={name}
											id={option.id || filterKey + '-' + option}
											value={option.name || option}
											onChange={this.handleInputChange}
										/>

										<label
											htmlFor={option.id || filterKey + '-' + option}
										>
											{option.name || option}
										</label>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
		)
	}
}

export default BrowseFilter;