import React, { Component } from 'react';

class Intro extends Component {
	render() {
		return (
			<div className="intro" style={{ backgroundImage: `url(${this.props.imageSrc})` }}></div>
		)
	}
}

export default Intro;