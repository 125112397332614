import React, { Component } from 'react';

import { withRouter } from 'react-router-dom'

import { Link } from "react-router-dom";

import { connect } from 'react-redux';

import { changeSearchBoxToggle, changeFiltersToggle, changeUniversalDropdownToggle } from '../../store/state/search-toggles/actions';

import BlankButton from '../common/blank-button';
import BrowseFilters from '../browse-filters/browse-filters';
import SearchBox from '../search-box/search-box';
import UniversalDropdown from '../universal-dropdown/universal-dropdown';
import rootReducer from '../../store/reducer';
import { createStore, compose } from 'redux';
import { Auth } from 'aws-amplify';
import Index from '../../views/index';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, {}, composeEnhancers());
const classNames = require('classnames');

class Header extends Component {

	constructor() {
		super();
		this.state = {
			itemsQuantity: 0
		}
		this.showItemsQuantity = this.showItemsQuantity.bind(this)
	}

	componentDidMount() {
		if (this.props.location.pathname == '/') {
			this.props.changeFiltersToggle(true);
		}
		this.setState({
			itemsQuantity: this.getTotalQuantity()
		});
	}

	componentWillUpdate() {
		if (this.state.itemsQuantity != this.getTotalQuantity()) {
			this.setState({
				itemsQuantity: this.getTotalQuantity()
			})
		}
	}

	componentDidUpdate() {
		if (!this.props.searchToggles.isBrowseFiltersVisible && !this.props.searchToggles.isSearchBoxVisible && !this.props.searchToggles.isUniversalDropdownVisible) {
			if (this.props.location.pathname == '/') {
				this.props.changeFiltersToggle(true);
			}
		} else if (this.props.location.pathname === "/adm" || this.props.location.pathname === "/adm-cart"
			|| this.props.location.pathname === "/analytics" || this.props.location.pathname === "/parts-ordered") {

			this.props.changeFiltersToggle(false);
		}
		const hasOpenedSearch = this.props.searchToggles.isSearchBoxVisible || this.props.searchToggles.isBrowseFiltersVisible || this.props.searchToggles.isUniversalDropdownVisible;
		document.querySelector('html').classList.toggle('filters-active', hasOpenedSearch);
	}

	getTotalQuantity() {
		let quantity = 0;
		if (localStorage['items']) {
			for (let item of JSON.parse(localStorage['items'])) {
				quantity = quantity + item.quantity;
			}
		} else {
			quantity = 0;
		}
		return quantity;
	}

	toggleSearchBox = () => {
		this.props.changeSearchBoxToggle(!this.props.searchToggles.isSearchBoxVisible);
		this.props.changeFiltersToggle(false);
		this.props.changeUniversalDropdownToggle(false);
	}

	toggleBrowseFilters = () => {
		this.props.changeFiltersToggle(!this.props.searchToggles.isBrowseFiltersVisible);
		this.props.changeSearchBoxToggle(false)
		this.props.changeUniversalDropdownToggle(false);
	}

	toggleUniversalDropdown = () => {
		this.props.changeUniversalDropdownToggle(!this.props.searchToggles.isUniversalDropdownVisible);
		this.props.changeFiltersToggle(false);
		this.props.changeSearchBoxToggle(false)
	}

	handleSearchBtnClick = () => {
		this.toggleSearchBox();
	}

	handleBrowseFiltersBtnClick = () => {
		this.toggleBrowseFilters();
	}

	handleUniversalBtnClick = () => {
		this.toggleUniversalDropdown();
	}

	closeToggles() {
		if (this.props.searchToggles.isSearchBoxVisible) {
			this.props.changeSearchBoxToggle(false)
		}
		if (this.props.searchToggles.isUniversalDropdownVisible) {
			this.props.changeUniversalDropdownToggle(false);
		}
	}

	showItemsQuantity() {
		if (this.props.location.pathname !== "/adm" && this.props.location.pathname !== "/adm-cart" &&
		this.props.location.pathname !== "/parts-ordered" && this.props.location.pathname !== "/analytics") {
			return (
				<div className="items__quantity">
					<b>{this.state.itemsQuantity}</b>
				</div>
			)
		}
	}

	handleLogout = () => {
		Auth.signOut()
			.then(() => {
				// this.setState({ 
				// 	signedIn: false, 
				// 	isSigningOut: false,
				// 	tokenId: '',
				// 	refreshToken: ''
				// });
				this.props.history.replace({
					pathname: '/',
				});
			})
			.catch((err) => {
				// console.log(err);
			});
	}

	render() {
		const { isSearchBoxVisible, isBrowseFiltersVisible, isUniversalDropdownVisible } = this.props.searchToggles;
		const headerWrapClass = classNames({
			'header-wrap': true,
			'header-wrap--hide-filters': this.props.location.pathname !== '/'
		});

		const backToAcdelcoLink = this.props.location.pathname !== "/adm" && this.props.location.pathname !== "/adm-cart"
			&& this.props.location.pathname !== "/analytics" && this.props.location.pathname !== "/parts-ordered"
			?
			<div className="back__acdelco__arabia" style={{ position: 'relative', marginRight: '20px' }}>
				<a href='https://www.acdelcoarabia.com'>
					<img style={{ display: 'inline-block', verticalAlign: 'middle' }} alt="Back to ACDelco"
						src={require('../../assets/images/backToACDelcoArabia.png').default} />
				</a>
			</div>
			:
			<div style={{ display: 'flex' }}>

				<span className="control__panel">CONTROL PANEL</span>
			</div>
			;

		const headerButtons = this.props.location.pathname !== "/adm" && this.props.location.pathname !== "/adm-cart"
			&& this.props.location.pathname !== "/analytics" && this.props.location.pathname !== "/parts-ordered"
			?
			<ul>
				<li>
					<BlankButton
						handleBtnClick={this.handleBrowseFiltersBtnClick}
					>Choose Vehicle</BlankButton>
				</li>

				<li>
					<BlankButton
						handleBtnClick={this.handleSearchBtnClick}
					>Search Parts</BlankButton>
				</li>
				<li>
					<BlankButton
						handleBtnClick={this.handleUniversalBtnClick}
					>Universal Parts</BlankButton>
				</li>
				<li>
					<Link to='/shop-list' className="shoplist">
						<img src={require('../../assets/images/shoplist-icon.png').default} alt="" />
					</Link>
					{this.showItemsQuantity()}
				</li>
			</ul>
			:
			<ul>
				<li>
					<Link to='/adm'>
						Shopping Carts
					</Link>
				</li>

				{/* <li>
					<Link to='/parts-ordered'>
						PARTS
					</Link>
				</li> */}
				<li>
					<Link to='/analytics'>
						ANALYTICS
					</Link>
				</li>
				<li>
					<a
						className={""}
						onClick={this.handleLogout}
						>
						LOGOUT
					</a>
				</li>
			</ul>
			;

		return (
			<div className={headerWrapClass}>
				<header className="header">
					<div className="shell">
						<div className="header__inner">
							<Link to="/" className="logo" onClick={() => this.closeToggles()}>
								<img src={require('../../assets/images/logo.png').default} alt="" />

								<span className="logo__slogan">
									PARTS

									<span>CATALOG</span>
								</span>
							</Link>
							<div style={{ display: 'flex' }}>

								{backToAcdelcoLink}

							</div>

							<Link to="/shop-list" className="mobile__shoplist">
								<img src={require('../../assets/images/shoplist-icon.png').default} alt="" />
							</Link>
							{this.showItemsQuantity()}


							<div className="header__links">

								{headerButtons}

							</div>
						</div>
					</div>
				</header>

				<div className="filters-toggles">
					<ul>
						<li>
							<BlankButton
								handleBtnClick={this.handleBrowseFiltersBtnClick}
							>Choose Vehicle</BlankButton>
						</li>

						<li>
							<BlankButton
								handleBtnClick={this.handleSearchBtnClick}
							>Search</BlankButton>
						</li>
						<li>
							<BlankButton
								handleBtnClick={this.handleUniversalBtnClick}
							>Universal Parts</BlankButton>
						</li>
					</ul>
				</div>

				<BrowseFilters
					active={isBrowseFiltersVisible}
				/>

				<SearchBox
					active={isSearchBoxVisible}
				/>

				<UniversalDropdown
					active={isUniversalDropdownVisible}
				/>

			</div>
		)
	}
}

export default withRouter(connect(
	(state) => ({
		searchToggles: state.searchToggles,
		itemShoplist: state.itemShoplist
	}),
	{
		changeSearchBoxToggle,
		changeFiltersToggle,
		changeUniversalDropdownToggle
	}
)(Header));