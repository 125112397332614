import React, { Component } from 'react';
import { Checkbox, CheckboxGroup } from 'react-checkbox-group';
import { Scrollbars } from 'react-custom-scrollbars';
import AnimateHeight from 'react-animate-height';

const filtersStyle = {
	width: 220
}

const checkboxStyle = {
	width: 16,
	height: 16,
	verticalAlign: 'top',
	horizontalAlign: 'middle',
	margin: '3px 3px 3px 3px',
	backgroundColor: '#ff3300',
    color: '#ff3300',
    position: 'relative',
	opacity: '1'
}


class SearchPageFilter extends Component {

    constructor() {
		super();
		this.state = {
			showFilterResults: true,
			height: 'auto'
		}
    }
    
    componentDidMount() {
        this.checkScreenSize();
    }
    
    checkScreenSize() {
        let mobileScreenSize = (window.innerWidth <= 1000);
        if (mobileScreenSize) {
            this.setState({
                showFilterResults: false
            });
        }else{
            this.setState({
                showFilterResults: true
            });
        }
    }

    yearChanged = (years) => {
        this.props.filterChanged(years, this.props.models, this.props.partNames)
    }

    modelChanged = (models) => {
        this.props.filterChanged(this.props.years, models, this.props.partNames)
    }

    partNameChanged = (partNames) => {
        this.props.filterChanged(this.props.years, this.props.models, partNames)
    }

    handleFilterResults = () => {
		let height;
		const { showFilterResults } = this.state
		
		if (!showFilterResults) {
			height = 'auto';
		} else {
			height = '10px';
		}
		this.setState({
			showFilterResults: !showFilterResults,
			height: height
		});
	}

    render() {

        //Get the list of years for each part
        let listYears = []
        if (this.props.acdelcos !== undefined) {
            this.props.acdelcos.map((acdelco, index) => {
                if (!listYears.includes(acdelco.acdelco.year)) {
                    listYears.push(acdelco.acdelco.year)
                }
            })
        }
        listYears.sort().reverse()

        //Get the list of years for the filter year
        let years = [];
        listYears.map((year, index) => {
            years.push([<Checkbox value={year} style={checkboxStyle} />, <label>{year}</label>, <br></br>]);
        })

        //Get the list of models for each part
        let listModels = []
        if (this.props.acdelcos !== undefined) {
            this.props.acdelcos.map((acdelco, index) => {
                if (!listModels.includes(acdelco.acdelco.make + " " + acdelco.acdelco.model)) {
                    listModels.push(acdelco.acdelco.make + " " + acdelco.acdelco.model)
                }
            })
        }
        listModels.sort()

        //Get the list of models for the filter models
        let models = [];
        listModels.map((model, index) => {
            models.push([<Checkbox value={model} style={checkboxStyle} />, <label>{model}</label>, <br></br>]);
        })

        //Get the list of partNames for each part
        let listPartNames = []
        if (this.props.acdelcos !== undefined) {
            this.props.acdelcos.map((acdelco, index) => {
                if (!listPartNames.includes(acdelco.acdelco.partName)) {
                    listPartNames.push(acdelco.acdelco.partName)
                }
            })
        }
        listPartNames.sort()

        //Get the list of partNames for the filter year
        let partNames = [];
        listPartNames.map((partName, index) => {
            partNames.push([<Checkbox value={partName} style={checkboxStyle} />, <label>{partName}</label>, <br></br>]);
        })

        years = 
            <div className="filter-checkbox">
                <h3 className="title-filter-small">Model Year:</h3>
                <Scrollbars
                    style={filtersStyle}
                    autoHeight={true}
                    autoHeightMax={300}>
                    <CheckboxGroup name="years" value={this.props.years} onChange={this.yearChanged}>
                        {years}
                    </CheckboxGroup>
                </Scrollbars>
            </div>
        models = 
            <div className="filter-checkbox">
                <h3 className="title-filter-small">Vehicle Model:</h3>
                <Scrollbars
                    style={filtersStyle}
                    autoHeight={true}
                    autoHeightMax={300}>
                    <CheckboxGroup name="models" value={this.props.models} onChange={this.modelChanged}>
                        {models}
                    </CheckboxGroup>
                </Scrollbars>
            </div>
        partNames = 
            <div className="filter-checkbox">
                <h3 className="title-filter-small">Part Name:</h3>
                <Scrollbars
                    style={filtersStyle}
                    autoHeight={true}
                    autoHeightMax={300}>
                    <CheckboxGroup name="partNames" value={this.props.partNames} onChange={this.partNameChanged}>
                        {partNames}
                    </CheckboxGroup>
                </Scrollbars>
            </div>

        return (
            <div className="filter-results-search">
				<div className="filter-results__title">
					<h3 onClick={() => this.handleFilterResults()}>Filter Results<h4>&nbsp;(Show/Hide)</h4></h3>
				</div>
                <AnimateHeight duration={700} height={this.state.height}>
                    {this.state.showFilterResults ?
                        <div className="filter-results__pop-up">
                            {years}                   
                            <br/>            
                            {models}                    
                            <br/>
                            {partNames}
                        </div>
                    :null}
                </AnimateHeight>
            </div>
        )
    }
}

export default SearchPageFilter;