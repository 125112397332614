import React, { Component } from 'react';
import UniversalItem from './universal-item';

class UniversalItemsList extends Component {

    constructor() {
        super();
    }

    render() {

        return (
            <div className="universal-parts-items-list">
                <div className="universal-parts-title">
                    <h1>{localStorage['universal-category'].replace("%20"," ").replace("%26","&").replace("%20"," ")}</h1>
                </div>
                
                {this.props.universalItems.map((universalItem) => {
                    return (
                        <div>
                            <UniversalItem
                            acdelco={universalItem.universalPart}/>
                            <br></br>
                            <br></br>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default UniversalItemsList;