import React, { Component } from 'react';

import Intro from '../components/intro/intro';
import Welcome from '../components/welcome/welcome';
import Articles from '../components/article/articles';
import Article from '../components/article/article';

class Index extends Component {
	render() {
		return (
			<div className="main">
				<Intro imageSrc={require('../assets/images/intro-image.jpg').default} />

				<Welcome
					title="ACDelco Parts Catalog"
					entry1="ACDelco is the premier supplier of aftermarket parts and products that cover most GM vehicles as well as many other makes and models. As a global leader in automotive replacement parts and related services, our products can be found around the globe and our focus has always been on building a reputation as the number-one source for quality auto parts."
	                entry2="ACDelco provides high-quality parts for all major vehicle systems, as well as off-road and marine equipment. Our product portfolio includes maintenance parts such as car batteries, fluids & lubricants, chemicals, spark plugs, oil filters, air filters, wiper blades, and brakes. Our products offer fit, form and function for most makes and models at an affordable price."
	                entry3="Find your parts today by selecting your vehicle or searching by part number."
	            />

				{/* <Articles>
					<Article
						imageSrc={require('../assets/images/article-image-1.jpg').default}

						title="MEET OUR PARTS"

						entry="Dependable. Durable. Engineered and tested to the highest standards. Under the ACDelco brand, high-quality parts are supplied for all major systems of passenger cars, SUVs and even for industrial and marine equipment."

						link='https://www.acdelcoarabia.com/acdelco-parts/all-parts.html'
					/>

					<Article
						imageSrc={require('../assets/images/article-image-2.jpg').default}

						title="LOCATE PARTS STORE"

						entry="Make sure you use the quality ACDelco auto parts you know and trust. Locate an authorized service center or parts store."
					
						link='https://www.acdelcoarabia.com/locate-service-auto-parts/locate-auto-parts-store.html'
					/>

					<Article
						imageSrc={require('../assets/images/article-image-3.jpg').default}

						title="VEHICLE MAINTENANCE"

						entry="Whether you have a trusted technician who works on your vehicles or you prefer to do the work yourself, let ACDelco help you keep your cars and trucks running strong, long and efficiently. Find tips and guidelines for keeping your vehicle in optimal condition."
					
						link='https://www.acdelcoarabia.com/vehicle-maintenance-tips/importance-of-maintenance.html'
					/>
				</Articles> */}
			</div>
		)
	}

};

export default Index;