import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeSearchToggles } from '../../store/state/search-toggles/actions';
import { withRouter } from 'react-router-dom'
import { Accordion, AccordionItem } from 'react-sanfona';
import { changeUniversalDropdownToggle } from '../../store/state/search-toggles/actions';
import { ACDELCO_API } from '../../endpoints/endpoints';

class UniversalDropdown extends Component {

    constructor() {
		super();

		this.state = {
            batteries: [],
            chemicals: []
		}
    }

    componentDidMount () {
        const category = "Fluids%20%26%20Chemicals"
        const query = ACDELCO_API.defaults.baseURL + '/universal-parts-subcategories'+'?category='+category;

		ACDELCO_API.get(query)
			.then(response => {
				this.setState({
                    chemicals: response.data.contents
                });
			})
    }

    componentDidUpdate(){
		
		if(window.innerWidth <= 1000){
			if(this.props.active){
				if(this.props.location.pathname !== '/'){
					this.props.changeUniversalDropdownToggle(false);
				}
			}
		}
	}

    // getUniversalParts(category) {
	// 	const query = ACDELCO_API.defaults.baseURL + '/universal-parts-subcategories'+'?category='+category;

	// 	ACDELCO_API.get(query)
	// 		.then(response => {
	// 			this.setState({
    //                 chemicals: response.data.contents
    //             });
	// 		})
    // }
    
    submitSelection(category, subcategory) {
        
        localStorage['universal-category'] = category;
        localStorage['universal-subcategory'] = subcategory;
		this.props.history.replace({
            pathname: '/universal-parts',
        });

        this.props.closeSearchToggles();
    }

    render() {

        // this.getUniversalParts('Chemicals');

        if (!this.props.active) {
            return null;
        }

        return (
            <div className="universal-dropdown">
                <div className="batteries-dropdown">
                    <Accordion>
                        <AccordionItem onExpand={() => {this.submitSelection('Batteries', '');}} title={'Batteries'}>
 
                        </AccordionItem>
                    </Accordion>
                </div>
                <div className="chemicals-dropdown">
                    <Accordion>
                        <AccordionItem title={'Chemicals'}>
                        {
                            this.state.chemicals.map((subcategory) => {
                                return(
                                <div className="universal-item" onClick={() => {this.submitSelection('Fluids%20%26%20Chemicals',subcategory);}}>
                                    <a>{subcategory}</a>
                                </div>
                                )
                            })
                        }
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        );
    }
}
export default withRouter(connect(
    null,
    {
        changeUniversalDropdownToggle,
        closeSearchToggles
    }
)(UniversalDropdown));