import React, { Component } from 'react';
import axios from 'axios';
import Iframe from 'react-iframe'



class Analytics extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
        }
    }



    render() {

        return (
            <Iframe url="https://datastudio.google.com/embed/u/0/reporting/1IBzj3CgUjbYzDklko2LMeYYF-oMx5CzG/page/1M"
                width="1200px"
                height="2500px"
                id="myId"
                className="google-data-studio"
                display="block"
                position="relative" />
        )
    }
};

export default Analytics