import { combineReducers } from 'redux';

import searchToggles from './state/search-toggles/reducer';

import { itemShoplist } from './state/item-shoplist/reducer';

export default combineReducers({
	searchToggles,
	itemShoplist
});
