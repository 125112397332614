import React, { Component } from 'react';

import SubCategoryButton from '../common/subcategory-button'

import { Accordion, AccordionItem } from 'react-sanfona';

class CategoryButton extends Component {

    constructor(props) {
        super();
        this.state = {
            firstCategoryCheck: true
        }
        this.toggleActive = this.toggleActive.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    shouldExpand() {
        if (this.state.firstCategoryCheck) {
            if (localStorage['category'] !== undefined) {
                if (localStorage['category'] === this.props.category) {
                    this.props.handler([this.props.categoryIndex]);
                    this.setState({
                        firstCategoryCheck: false
                    })
                    return true;
                }
            }
        }
        return this.props.activeClickedItems.includes(this.props.categoryIndex);
    }

    toggleActive(index) {
        const position = this.props.activeClickedItems.indexOf(index);
        if (position !== -1) {
            this.props.handler([-1]);
        } else {
            this.props.handler([index]);
        }
    }

    handleClick({ activeItems }) {
        this.setState({ activeClickedItems: activeItems });
    }

    render() {
        let selectedSubCategoryHandler = this.props.selectedSubCategoryHandler
        return (
            <li>
                <div className="dropdownlink" onClick={() => {
                    this.toggleActive(this.props.categoryIndex);
                    this.props.handleCategoryClick();
                }}
                >
                    <i aria-hidden="true">{this.props.category}</i></div>
                <Accordion>
                    <AccordionItem
                        expanded={this.shouldExpand()}>
                        <ul className="submenuItems">
                            {
                                this.props.subCategories[this.props.categoryIndex].map((subCategory, index) => {
                                    return (
                                        <SubCategoryButton
                                            key={index}
                                            handleSubCategoryClick={function () {
                                                selectedSubCategoryHandler(subCategory);
                                            }}
                                            handleFilterResults={this.props.handleFilterResults}
                                            subCategory={subCategory.subCategoryName}
                                            selectedSubCategory={this.props.selectedSubCategory}
                                        >{subCategory}</SubCategoryButton>
                                    )
                                })
                            }
                        </ul>
                    </AccordionItem>
                </Accordion>
            </li>
        )
    }
}

export default CategoryButton;
