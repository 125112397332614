import React, { Component } from 'react';
import { Checkbox, CheckboxGroup } from 'react-checkbox-group';
import { Scrollbars } from 'react-custom-scrollbars';
import AnimateHeight from 'react-animate-height';

const filtersStyle = {
	width: 220
}

const checkboxStyle = {
	width: 16,
	height: 16,
	verticalAlign: 'top',
	horizontalAlign: 'middle',
	margin: '3px 3px 3px 3px',
	backgroundColor: '#ff3300',
    color: '#ff3300',
    position: 'relative',
	opacity: '1'
}


class UniversalPartsFilter extends Component {

    constructor() {
		super();
		this.state = {
			showFilterResults: true,
			height: 'auto'
		}
    }
    
    componentDidMount() {
        this.checkScreenSize();
    }
    
    checkScreenSize() {
        let mobileScreenSize = (window.innerWidth <= 1000);
        if (mobileScreenSize) {
            this.setState({
                showFilterResults: false
            });
        }else{
            this.setState({
                showFilterResults: true
            });
        }
    }

    partNameChanged = (partNames) => {
        this.props.filterChanged(partNames, this.props.amperages, this.props.ampHours)
    }

    amperageChanged = (amperages) => {
        this.props.filterChanged(this.props.partNames, amperages, this.props.ampHours)
    }
    
    ampHourChanged = (ampHours) => {
        this.props.filterChanged(this.props.partNames, this.props.amperages, ampHours)
    }

    handleFilterResults = () => {
		let height;
		const { showFilterResults } = this.state
		
		if (!showFilterResults) {
			height = 'auto';
		} else {
			height = '10px';
		}
		this.setState({
			showFilterResults: !showFilterResults,
			height: height
		});
	}

    render() {

        let listPartNames = []
        let listAmperages = []
        let listAmpHours = []

        if (this.props.universalItems !== undefined) {
            this.props.universalItems.map((acdelco, index) => {

                //Get the list of partNames for each part
                if (!listPartNames.includes(acdelco.universalPart.partName)) {
                    listPartNames.push(acdelco.universalPart.partName)
                }

                acdelco.universalPart.specifications.map((specification, index) => {
                    var amperage = specification.specification.substring("Cold Cranking Amperage: ".length).replace(" A","");
                    var ampHour = specification.specification.substring("Amp Hour: ".length).replace(" Ah","");

                    if(specification.specification.includes("Cold Cranking Amperage: ")){
                        //Get the list of amperage for each part
                        if (!listAmperages.includes(amperage)) {
                            listAmperages.push(amperage);
                        }
                    }else if(specification.specification.includes("Amp Hour: ")){
                        //Get the list of amp hour for each part
                        if (!listAmpHours.includes(ampHour)) {
                            listAmpHours.push(ampHour);
                        }
                    }
                })

            })
        }

        listPartNames.sort()
        listAmperages.sort(function(a, b){return a-b})
        listAmpHours.sort(function(a, b){return a-b})

        //Get the list of partNames for the filter
        let partNamesCheckbox = [];
        listPartNames.map((partName, index) => {
            partNamesCheckbox.push([<Checkbox value={partName} style={checkboxStyle} />, <label>{partName}</label>, <br></br>]);
        })

        //Get the list of amperage for the filter
        let amperagesCheckbox = [];
        listAmperages.map((amperage, index) => {
            amperagesCheckbox.push([<Checkbox value={amperage} style={checkboxStyle} />, <label>{amperage+" A"}</label>, <br></br>]);
        })

        //Get the list of ampHour for the filter
        let ampHoursCheckbox = [];
        listAmpHours.map((ampHour, index) => {
            ampHoursCheckbox.push([<Checkbox value={ampHour} style={checkboxStyle} />, <label>{ampHour+" Ah"}</label>, <br></br>]);
        })

        let partNames = partNamesCheckbox.length > 0 ?
            <div className="filter-checkbox">
                <h3 className="title-filter-small">Part Name:</h3>
                <Scrollbars
                    style={filtersStyle}
                    autoHeight={true}
                    autoHeightMax={300}>
                    <CheckboxGroup name="partNames" value={this.props.partNames} onChange={this.partNameChanged}>
                        {partNamesCheckbox}
                    </CheckboxGroup>
                </Scrollbars>
            </div>
            :"";

        let amperages = amperagesCheckbox.length > 0 ?
            <div className="filter-checkbox">
                <h3 className="title-filter-small">Cold Cracking Amperage:</h3>
                <Scrollbars
                    style={filtersStyle}
                    autoHeight={true}
                    autoHeightMax={300}>
                    <CheckboxGroup name="amperages" value={this.props.amperages} onChange={this.amperageChanged}>
                        {amperagesCheckbox}
                    </CheckboxGroup>
                </Scrollbars>
            </div>
            :"";

        let ampHours = ampHoursCheckbox.length > 0 ?
            <div className="filter-checkbox">
                <h3 className="title-filter-small">Amp Hour:</h3>
                <Scrollbars
                    style={filtersStyle}
                    autoHeight={true}
                    autoHeightMax={300}>
                    <CheckboxGroup name="ampHours" value={this.props.ampHours} onChange={this.ampHourChanged}>
                        {ampHoursCheckbox}
                    </CheckboxGroup>
                </Scrollbars>
            </div>
            :"";

        return (
            <div className="filter-results-universal">
				<div className="filter-results__title">
                    <h3 onClick={() => this.handleFilterResults()}>Filter Results<h4>&nbsp;(Show/Hide)</h4></h3>
				</div>
                <AnimateHeight duration={700} height={this.state.height}>
                    {this.state.showFilterResults ?
                        <div className="filter-results__pop-up">
                            {partNames}
                            {amperages !== "" ?
                                <br/>
                            :null}
                            {amperages}
                            {ampHours !== "" ?
                                <br/>
                            :null}
                            {ampHours}
                        </div>
                    :null}
                </AnimateHeight>
            </div>
        )
    }
}

export default UniversalPartsFilter;