import React, { Component } from 'react';
import UniversalItemsList from '../components/items/universal-items-list';
import UniversalPartsFilter from '../components/filters/universal-parts-filter';
import Loader from '../components/common/loader';
import { ACDELCO_API } from '../endpoints/endpoints';

class UniversalPage extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            universalItems: [],
            listParts: [],
            partNames: [],
            amperages: [],
            ampHours: [],
            category: "",
            subcategory: ""
        }
    }

    componentDidMount() {
        this.getParts();
    }

    componentDidUpdate(){
        if(this.state.category){
            if(this.state.subcategory){
                if(this.state.subcategory !== localStorage['universal-subcategory']){
                    this.getParts()
                }
            }else{
                if(this.state.category !== localStorage['universal-category']){
                    this.getParts()
                }
            }
        }
    }

    getParts() {
        window.scrollTo(0, 0);

        this.setState({
            isLoading: true,
            category: "",
            subcategory: ""
        });

        const searchQuery = 'category='+ localStorage['universal-category'] +'&subcategory='+ localStorage['universal-subcategory'];

        const query = ACDELCO_API.defaults.baseURL + '/universal-parts-items?' + searchQuery;
        ACDELCO_API.get(query)
            .then(response => {
                this.setState({
                    isLoading: false,
                    universalItems: response.data.contents,
                    listParts: response.data.contents,
                    category: localStorage['universal-category'],
                    subcategory: localStorage['universal-subcategory']
                })
            })

    }

    findAmperageAmpHour(acdelco, amperages, ampHours){
        var amperagesExists = false;
        var ampHoursExists = false
        acdelco.universalPart.specifications.map((specification) => {
            var amperage = specification.specification.substring("Cold Cranking Amperage: ".length).replace(" A","");
            var ampHour = specification.specification.substring("Amp Hour: ".length).replace(" Ah","");

            if((amperages !== 0) && specification.specification.includes("Cold Cranking Amperage: ")){
                if (amperages.includes(amperage)) {
                    amperagesExists = true;
                }
            }else if((ampHours.length !== 0) && specification.specification.includes("Amp Hour: ")){
                if (ampHours.includes(ampHour)) {
                    ampHoursExists = true;
                }
            }
        })

        if(amperages.length !== 0 && ampHours.length !== 0){
            return (amperagesExists && ampHoursExists);
        }else if(amperages.length !== 0){
            return amperagesExists;
        }else if(ampHours.length !== 0){
            return ampHoursExists;
        }
    }

    handleFilterChange = (partNames, amperages, ampHours) => {
        
        let listParts = [];
        
        if ((partNames.length == 0) && (amperages.length == 0) && (ampHours.length == 0)) {
			this.state.universalItems.map((acdelco) => {
				listParts.push(acdelco)
			})
		} else {
			this.state.universalItems.map((acdelco) => {

                if ((partNames.length !== 0)){
                    if(amperages.length !== 0 || ampHours.length !== 0){
                        if(partNames.includes(acdelco.universalPart.partName) && this.findAmperageAmpHour(acdelco, amperages, ampHours)){
                            listParts.push(acdelco);
                        }
                    }else if(partNames.includes(acdelco.universalPart.partName)){
                        listParts.push(acdelco);
                    }
                }else{
                    if(this.findAmperageAmpHour(acdelco, amperages, ampHours)){
                        listParts.push(acdelco);
                    }
                }
                
			})
        }

		this.setState({
            listParts: listParts,
            partNames: partNames,
            amperages: amperages,
            ampHours: ampHours
		})
	}

    render() {
        if (this.state.isLoading) {
            return (
                <Loader />
            )
        }
        return (
            <div className="main">
                <div className="shell">
                    <div className="universal-parts-items">
                        <aside className="sidebar">
                            <UniversalPartsFilter
                                universalItems={this.state.universalItems}
                                filterChanged={this.handleFilterChange}
                                partNames={this.state.partNames}
                                amperages={this.state.amperages}
                                ampHours={this.state.ampHours}
                            />
                        </aside>
                        <UniversalItemsList
                            universalItems={this.state.listParts}
                        />
                    </div>
                </div>
            </div>
        )
    }

};

export default UniversalPage;