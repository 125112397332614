import React, { Component } from 'react';

class Footer extends Component {
	render() {
		return (
			<footer className="footer">
				<div className="shell">
					<div className="footer__nav">
						<ul>
							{/* <li>
								<a href="">Site Map</a>
							</li> */}
							
							<li>
								<a target="_blank" rel="noopener noreferrer" href="https://www.acdelcoarabia.com/privacypolicy.html">Privacy Policy</a>
							</li>
							
							{/* <li>
								<a href="">Legal Information</a>
							</li> */}
						</ul>
					</div>

					<div className="copyright">
						<p>&copy; 2019 All rights reserved. Total or partial reproduction prohibited</p>
					</div>
				</div>
			</footer>
		)
	}
}

export default Footer;