import React, { Component } from "react";
import { ADMIN_API } from "../endpoints/endpoints";
import Pagination from "../components/items/pagination";
import Loader from "../components/common/loader";
import ExcelExport from '../components/excel-exporter/excel-export'

class PartsOrdered extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCartItems: [],
      isLoading: true,
      currentPage: null,
      totalPages: null,
      cartItems: []
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    const query = ADMIN_API.defaults.baseURL + "/parts-ordered";
    ADMIN_API.get(query).then(response => {
      this.setState(
        {
          cartItems: response.data.contents,
          currentCartItems: []
        },
        this.setState({ isLoading: false })
      );
    });
  }

  renderTableHeader() {
    return (
      <tr>
        <th>{"ID"}</th>
        <th>{"ACDelco PN"}</th>
        <th>{"GM PN"}</th>
        <th>{"Part Name"}</th>
        <th>{"Total Qty"}</th>
      </tr>
    );
  }

  onPageChanged = data => {
    window.scrollTo(0, 0);

    let { cartItems } = this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentCartItems = cartItems.slice(offset, offset + pageLimit);

    this.setState({
      currentPage,
      currentCartItems: currentCartItems,
      totalPages
    });
  };

  render() {
    const { cartItems, currentPage, currentCartItems, isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }
    return (
      <div>
        <h1 id="titleDataCart">Parts ordered</h1>
        <table id="adm" className="dataCart">
          <tbody>
            {this.renderTableHeader()}
            {currentCartItems.map((cartItem, index) => {
              return (
                <tr key={cartItem.cartItem.id}>
                  <td>{cartItem.cartItem.id}</td>
                  <td>{cartItem.cartItem.partNumber}</td>
                  <td>{cartItem.cartItem.gmPartNumber}</td>
                  <td>{cartItem.cartItem.partName}</td>
                  <td>{cartItem.cartItem.quantity}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div  className="table-footer">
       
          <div className="pagination-bottom">
            <div className="left">
              <h2>
                <strong className="text-secondary">Pages</strong>
              </h2>
            </div>
            <div className="left">
              <Pagination
                pageLimit={10}
                totalRecords={cartItems.length + 1}
                pageNeighbours={1}
                onPageChanged={this.onPageChanged}
                currentPage={currentPage}
                             
              />
            </div>  
            <div className="inner">  
             <ExcelExport innerField={'cartItem'} dataSet={cartItems} fields={['id','partNumber','gmPartNumber','partName','quantity' ]} fileName={'Parts ordered'}/> 
             </div>
          </div>
        </div>

      </div>
    );
  }
}

export default PartsOrdered;
