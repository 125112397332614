import React, { Component } from 'react';

class Button extends Component {

	constructor(props) {
		super(props)
	}

	render() {
		let className = 'btn';

		if (this.props.className !== undefined) {
			className = `${className} ${this.props.className}`
		}

		if (this.props.block) {
			className = `${className} btn--block`
		}

		if (this.props.href) {
			return (
				<a href={this.props.href} className={className}>
					{this.props.children}
				</a>
			)
		} else {
				return (
					<button
						className={className}
					>
						{this.props.children}
					</button>
				)	
		}
	}
}

export default Button;