import React, { Component } from 'react';

import BrowseFilter from './browse-filter';
// import Loader from '../common/loader';
import BlankButton from '../common/blank-button';
import { connect } from 'react-redux';
import { closeSearchToggles } from '../../store/state/search-toggles/actions';
import { changeSearchBoxToggle, changeFiltersToggle , changeUniversalDropdownToggle} from '../../store/state/search-toggles/actions';
import { ACDELCO_API } from '../../endpoints/endpoints';

import { withRouter } from 'react-router-dom'

const queryString = require('query-string');

let STEPS = [
	{
		step: 0,
		name: 'Year',
		nextStep: 1,
		filterKey: 'year',
		queryKey: '/years'
	},
	{
		step: 1,
		name: 'Make',
		nextStep: 2,
		filterKey: 'make',
		queryKey: '/makes'
	},
	{
		step: 2,
		name: 'Model',
		nextStep: 3,
		filterKey: 'model',
		queryKey: '/models'
	}
];

const initialState = () => ({
	currentStep: 'step-1',
	filters: {},
	isLoading: false,
	searchQuery: '',
	currentStepIndex: 0,
	contents: [[], [], [], [], []]
})

const initialStateYears = (years) => ({
	currentStep: 'step-1',
	filters: {},
	isLoading: false,
	searchQuery: '',
	currentStepIndex: 0,
	contents: [[...years], [], [], [], []]
})

class BrowseFilters extends Component {
	constructor() {
		super();

		this.state = initialState();
	}

	componentDidMount() {

		this.setState({
			isLoading: true
		});

		const searchQuery = queryString.stringify(this.props.filters);
		const query = ACDELCO_API.defaults.baseURL + STEPS[0].queryKey + '?' + searchQuery;

		ACDELCO_API.get(query)
			.then(response => {
				let tempContents = this.state.contents.slice();
				tempContents[0] = [...response.data.contents]
				tempContents[1] = []
				tempContents[2] = []
				tempContents[3] = []
				tempContents[4] = []
				this.setState({
					options: response.data.contents,
					isLoading: false,
					contents: tempContents
				});
			})
	}

	componentDidUpdate(){
		
		if(window.innerWidth <= 1000){
			if(this.props.searchToggles.isBrowseFiltersVisible){
				if(this.props.location.pathname !== '/'){
					this.props.changeFiltersToggle(false);
				}
			}
		}
		if(this.props.history.location.state){
			if(this.props.history.location.state.year){
				this.handleInputChange(0,'year',this.props.history.location.state.year);
				if(this.props.history.location.state.make !== null){
					this.props.history.replace({
						state: {
							make: this.props.history.location.state.make
						}
					});
				}else{
					this.props.history.replace({
						state: undefined
					});
				}
			}
		}
		
		
	}

	getCategoriesFromFilters() {

		localStorage.removeItem("category");
		localStorage.removeItem("subCategory")

		let searchQuery = "";
		for (let filter in this.state.filters) {
			if (this.state.filters.hasOwnProperty(filter)) {
				searchQuery += filter + '=' + this.state.filters[filter].id + '&';
			}
		}

		const query = ACDELCO_API.defaults.baseURL + '/categories?' + searchQuery;

		this.props.closeSearchToggles();
		this.props.history.push({
			pathname: '/load',
		});
		ACDELCO_API.get(query.replace(/\\/g,'%5C'))
			.then(response => {
				localStorage.setItem('categories', JSON.stringify(Object.keys(response.data)));
				localStorage.setItem('subCategories', JSON.stringify(Object.values(response.data)));
				localStorage.setItem('filters', JSON.stringify(this.state.filters));

				this.submitFilters();
			})
	}

	submitFilters = () => {

		this.props.history.replace({
			pathname: '/categories',
			state: {
				subCategories: [...localStorage.getItem('subCategories')]
			}
		});

		this.props.closeSearchToggles();

		this.resetFilters();
	}

	resetFilters = () => {
		this.setState(initialStateYears(this.state.contents[0]));
	}

	handleInputChange = (step, key, value) => {
		if (step === 2) {
			this.saveFilter(key, value, step, function () {
				this.getCategoriesFromFilters()
			});
		} else {
			this.saveFilter(key, value, step, function () {
				this.callAcdelcoApi(step)
			});
		}
		this.setState({
			currentStepIndex: step + 1
		})
	}

	saveFilter = (key, value, step, callback) => {

		let tempFilters = Object.assign({}, this.state.filters);
		tempFilters[key] = this.state.contents[step].find(x => x.name === value);
		Object.keys(tempFilters).forEach(function (key, index) {
			if (index > step) {
				delete tempFilters[key];
			}
		});

		this.setState({
			filters: tempFilters
		}, callback);
	}

	callAcdelcoApi(stepIndex) {
		this.setState({
			isLoading: true
		});

		let searchQuery = "";

		for (let filter in this.state.filters) {
			if (this.state.filters.hasOwnProperty(filter)) {
				searchQuery += filter + '=' + this.state.filters[filter].name + '&';
			}
		}

		let query;
		if (this.state.contents[0].length === 0) {
			query = ACDELCO_API.defaults.baseURL + STEPS[stepIndex].queryKey + '?' + searchQuery;
		} else {
			query = ACDELCO_API.defaults.baseURL + STEPS[stepIndex + 1].queryKey + '?' + searchQuery;
		}



		ACDELCO_API.get(query)
			.then(response => {
				let tempContents = this.state.contents.slice();
				tempContents[stepIndex + 1] = [...response.data.contents]
				for (let i = stepIndex + 2; i < STEPS.length; i++) {
					tempContents[i] = [];
				}
				this.setState({
					options: response.data.contents,
					isLoading: false,
					contents: tempContents
				});

				if(this.props.history.location.state){
					if(this.props.history.location.state.make !== undefined && this.props.history.location.state.make !== null){
						this.handleInputChange(1,'make',this.props.history.location.state.make);
						this.props.history.replace({
							state: undefined
						})
					}
				}
			})
	}

	handleBackButtonClick = () => {
		this.resetFilters();
		this.props.closeSearchToggles();
		this.props.history.push('/');
		
		/*Back to the previous filter
		if (this.state.currentStepIndex === 0) {
			this.resetFilters();
			this.props.closeSearchToggles();
			this.props.history.push('/');
		}else{
			let tempFilters = this.state.filters;
			delete tempFilters[STEPS[this.state.currentStepIndex].filterKey];
			delete tempFilters[STEPS[this.state.currentStepIndex-1].filterKey];
			let tempContents = this.state.contents;
			tempContents[this.state.currentStepIndex] = [];
			this.setState({
				contents: tempContents,
				currentStepIndex: this.state.currentStepIndex-1,
				filters: tempFilters
			})
		}*/
	}

	toggleSearchBox = () => {
		this.props.changeSearchBoxToggle(!this.props.searchToggles.isSearchBoxVisible);
		this.props.changeFiltersToggle(false);
		this.props.changeUniversalDropdownToggle(false);
	}

	toggleUniversalDropdown = () => {
		this.props.changeUniversalDropdownToggle(!this.props.searchToggles.isUniversalDropdownVisible);
		this.props.changeFiltersToggle(false);
		this.props.changeSearchBoxToggle(false)
	}

	handleSearchBtnClick = () => {	
		this.toggleSearchBox();
	}

	handleUniversalBtnClick = () => {
		this.toggleUniversalDropdown();
	}

	render() {

		const { filters } = this.state;

		if (!this.props.active) {
			return null;
		}

		// if(this.state.isLoading) {
		// 	return (
		// 		<Loader />
		// 	)
		// }

		return (
			<div className="browse-filters">
				<div className="browse-filters-web">
					<div className="shell">
						{
							this.state.contents.filter(index => index).map((content, index) => {
								return (
									content.length !== 0 && (
										<BrowseFilter
											key={STEPS[index].name}
											name={STEPS[index].name}
											options={content}
											filterKey={STEPS[index].filterKey}
											queryKey={STEPS[index].queryKey}
											filters={filters}
											filterChange={(value) => this.handleInputChange(STEPS[index].step, STEPS[index].filterKey, value)}
											handleBackButtonClick={this.handleBackButtonClick}
											pathname={this.props.location.pathname}
										/>
									)
								)
							})
						}
					</div>
				</div>
				<div className="browse-filters-mobile" id="browse-filters-mobile">
					<div className="shell">
						<div className="filters-toggles">
							<ul>
								<li>
									<BlankButton
										handleBtnClick={this.handleBrowseFiltersBtnClick}
									>Choose Vehicle</BlankButton>
								</li>
								<li>
									<BlankButton
										handleBtnClick={this.handleSearchBtnClick}
									>Search</BlankButton>
								</li>
								<li>
									<BlankButton
										handleBtnClick={this.handleUniversalBtnClick}
									>Universal Parts</BlankButton>
								</li>
							</ul>
						</div>

						{
							filters[Object.keys(filters)[0]] !== undefined && (
								<ul className="browse-filters__selected-filters">
									{
										Object.keys(filters).map((filter, index) => filters[filter] !== '' && (
											<li key={filter + index}>
												<span>{filter}:</span>

												<strong>{filters[filter].name}</strong>
											</li>
										))
									}
								</ul>
							)

						}

						{
							this.state.contents.map((content, index) => {
								return (
									content.length !== 0 && index === this.state.currentStepIndex && (
										<BrowseFilter
											key={STEPS[index].name}
											name={STEPS[index].name}
											options={content}
											filterKey={STEPS[index].filterKey}
											queryKey={STEPS[index].queryKey}
											filters={filters}
											filterChange={(value) => this.handleInputChange(STEPS[index].step, STEPS[index].filterKey, value)}
											handleBackButtonClick={this.handleBackButtonClick}
											pathname={this.props.location.pathname}
										/>
									)
								)
							})
						}
					</div>
				</div>
			</div >
		)
	}
}

export default withRouter(connect(
	(state) => ({
		searchToggles: state.searchToggles
	}),
	{
		changeSearchBoxToggle,
		changeFiltersToggle,
		changeUniversalDropdownToggle,
		closeSearchToggles
	}
)(BrowseFilters));