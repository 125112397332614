// src/components/UnderMaintenance.js
import React from 'react';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    fontSize: '2rem', // Adjust the font size as needed
  },
  heading: {
    fontSize: '4rem', // Adjust the font size as needed
  },
  paragraph: {
    fontSize: '1.5rem', // Adjust the font size as needed
  }
};

const UnderMaintenance = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Under Maintenance</h1>
      <p style={styles.paragraph}>We are currently performing maintenance. Please check back later.</p>
    </div>
  );
};

export default UnderMaintenance;
