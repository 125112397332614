import React, { Component } from 'react';
import ExcelExport from '../components/excel-exporter/excel-export'


class AdminCart extends Component {

    constructor(props) {
        super(props)
    }

    renderTableHeader() {
        return (
            <tr>
                <th>{'ID'}</th>
                <th>{'ACDelco PN'}</th>
                <th>{'GM PN'}</th>
                <th>{'Part Name'}</th>
                <th>{'Total Qty'}</th>
            </tr>
        )
    }

    // goBack() {
    //     this.props.history.replace({
    // 		pathname: '/adm',
    // 		state: {

    // 		}
    // 	});
    //   }

    render() {

        // const cartItems =
        //     this.props.cartItems != undefined ?
        //         <div className="inner">
        //             <ExcelExport buttonText={'Download Parts'} innerField={'cartItem'} dataSet={this.props.cartItems} fields={['id', 'shopcartId','partNumber', 'gmPartNumber', 'partName', 'quantity']} fileName={'PARTS'} />
        //         </div>
        //         :
        //         "";

        return (
            <div>
                <h1 id='titleDataCart'>Shopping Cart ID #{this.props.cartId} - PARTS</h1>
                <table id='adm' className='dataCart'>
                    <tbody>
                        {this.renderTableHeader()}
                        {this.props.cartItems.map((item, index) => {
                            // {this.props.location.state.cartItems.map((item, index) => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.id} </td>
                                    <td>{item.partNumber}</td>
                                    <td>{item.gmPartNumber}</td>
                                    <td>{item.partName}</td>
                                    <td>{item.quantity}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {/* <button className="go-back-button" onClick={()=>this.goBack()}>Go back to shoppping carts</button> */}

                <div className="table-footer">
                    <div className="pagination-bottom">
                        <div className="left">
                            <h2>
                                <strong className="text-secondary"></strong>
                            </h2>
                        </div>
                        <div className="right">
                        </div>
                        {this.props.downloadParts}
                        <div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default AdminCart