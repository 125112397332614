import React, { Component } from 'react';

class BlankButton extends Component {
	render() {
		let className = '';

		if(this.props.className !== undefined){
			className = this.props.className;
		}

		return (
			<button
				className={`btn-blank ${ className }`}
				onClick={this.props.handleBtnClick}
			>
				{ this.props.children }
			</button>
		)
	}
}

export default BlankButton;
