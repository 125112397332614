import axios from 'axios';

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';


// const ADDRESS = 'http://localhost:8080'; /* dev */
// const ADDRESS = 'http://52.7.53.245:8080'; /* homolog */
const ADDRESS = 'https://api.pecachevrolet.com.br'; /* production */


export const ACDELCO_API = axios.create({
	baseURL:  ADDRESS + '/api/acdelco' 
});

export const SHOPPING_LIST_API = axios.create({
    baseURL: ADDRESS + '/api/shoppinglist'
});

export const ADMIN_API = axios.create({
    baseURL: ADDRESS + '/api/admin'
});

export const IMPORT_API = axios.create({
    baseURL: ADDRESS + '/api/import'
});
