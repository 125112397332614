import React, { Component } from "react";
import BlankButton from "../common/blank-button";
import Button from "../common/button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import Carousel from "re-carousel";
import IndicatorDots from "./indicator-dots";
import Buttons from "./buttons";
import AnimateHeight from "react-animate-height";
import NumericInput from "react-numeric-input";
import Modal from "react-modal";
import Magnifier from "react-magnifier";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateQuantity } from "../../store/state/item-shoplist/actions";
import Dropdown from "../drop-down-list/drop-down";

const defaultImageSrc = require("../../assets/images/temp/image-coming-soon.png");

const initialState = () => ({
  quantity: 1,
  showQuantity: false,
  showButton: true,
  showSpecifications: false,
  showFAQ: false,
  showFeaturesBenefits: false,
  showGoodMaitenancePractices: false,
  showWarranties: false,
  showSignsWearTroubleshooting: false,
  height: 0,
  modalIsOpen: false,
  width: {}
});

const customStyles = {
  overlay: {
    zIndex: "100",
    backgroundColor: "rgba(0,0,0,0.8)"
  },
  content: {
    overflow: "hidden",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "100"
  }
};

const divCarousel = {
  height: "100%",
  width: "fit-content",
  margin: "0 auto 0 auto"
};

const imageCarousel = {
  maxWidth: "100%",
  maxHeight: "100%",
  cursor: "pointer"
};

class ElasticItem extends Component {
  constructor() {
    super();
    this.state = initialState();
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onImgLoad = this.onImgLoad.bind(this);
  }

  increment = () => {
    if (this.state.quantity <= 98) {
      this.setState({
        quantity: this.state.quantity + 1
      });
    }
  };

  decrement = () => {
    if (this.state.quantity > 1) {
      this.setState({
        quantity: this.state.quantity - 1
      });
    }
  };

  updateQuantity = event => {
    this.setState({
      quantity: event
    });
  };

  checkShopList() {
    this.setState({
      showQuantity: !this.state.showQuantity,
      showButton: !this.state.showButton
    });
  }

  checkImage(imageSrc, onLoad, onError) {
    let img = new Image();
    img.onload = onLoad;
    img.onerror = onError;
    img.src = imageSrc;
  }

  checkInfo(info) {
    if (info !== "") {
      return <span>{info}</span>;
    } else {
      return <span>&#8203;</span>;
    }
  }

  checkInfoNotes(info) {
    if (info !== "" && info !== "###") {
      let splitInfos = info.split(" ### ");
      splitInfos[splitInfos.length - 1] = splitInfos[
        splitInfos.length - 1
      ].replace(" ###", "");
      const notes = splitInfos.map((splitInfo, index) => {
        return (
          <span>
            {splitInfo}
            <br />
          </span>
        );
      });
      return <div>{notes}</div>;
    } else {
      return <span>&#8203;</span>;
    }
  }

  addDefaultSrc(ev) {
    ev.target.src = defaultImageSrc;
  }

  handleSpecifications = () => {
    let height;
    const { showSpecifications } = this.state;

    if (
      typeof this.props.acdelco.specifications !== "undefined" &&
      !showSpecifications
    ) {
      height = "auto";
    } else {
      height = 0;
    }
    this.setState({
      showSpecifications: !showSpecifications,
      showFAQ: false,
      showFeaturesBenefits: false,
      showGoodMaitenancePractices: false,
      showWarranties: false,
      showSignsWearTroubleshooting: false,
      height: height
    });
  };

  handleFaq = () => {
    let height;
    const { showFAQ } = this.state;

    if (typeof this.props.acdelco.faq !== "undefined" && !showFAQ) {
      height = "auto";
    } else {
      height = 0;
    }
    this.setState({
      showSpecifications: false,
      showFAQ: !showFAQ,
      showFeaturesBenefits: false,
      showGoodMaitenancePractices: false,
      showWarranties: false,
      showSignsWearTroubleshooting: false,
      height: height
    });
  };

  handleFeaturesAndBenefits = () => {
    let height;
    const { showFeaturesBenefits } = this.state;

    if (
      typeof this.props.acdelco.featuresAndBenefits !== "undefined" &&
      !showFeaturesBenefits
    ) {
      height = "auto";
    } else {
      height = 0;
    }
    this.setState({
      showSpecifications: false,
      showFAQ: false,
      showFeaturesBenefits: !showFeaturesBenefits,
      showGoodMaitenancePractices: false,
      showWarranties: false,
      showSignsWearTroubleshooting: false,
      height: height
    });
  };

  handleGoodMaintenancePractices = () => {
    let height;
    const { showGoodMaitenancePractices } = this.state;

    if (
      typeof this.props.acdelco.goodMaintenancePractices !== "undefined" &&
      !showGoodMaitenancePractices
    ) {
      height = "auto";
    } else {
      height = 0;
    }
    this.setState({
      showSpecifications: false,
      showFAQ: false,
      showFeaturesBenefits: false,
      showGoodMaitenancePractices: !showGoodMaitenancePractices,
      showWarranties: false,
      showSignsWearTroubleshooting: false,
      height: height
    });
  };

  handleWarranties = () => {
    let height;
    const { showWarranties } = this.state;

    if (
      typeof this.props.acdelco.warranties !== "undefined" &&
      !showWarranties
    ) {
      height = "auto";
    } else {
      height = 0;
    }
    this.setState({
      showSpecifications: false,
      showFAQ: false,
      showFeaturesBenefits: false,
      showGoodMaitenancePractices: false,
      showWarranties: !showWarranties,
      showSignsWearTroubleshooting: false,
      height: height
    });
  };

  handleSignsOfWearAndTroubleshooting = () => {
    let height;
    const { showSignsWearTroubleshooting } = this.state;

    if (
      typeof this.props.acdelco.signsOfWearAndTroubleshooting !== "undefined" &&
      !showSignsWearTroubleshooting
    ) {
      height = "auto";
    } else {
      height = 0;
    }
    this.setState({
      showSpecifications: false,
      showFAQ: false,
      showFeaturesBenefits: false,
      showGoodMaitenancePractices: false,
      showWarranties: false,
      showSignsWearTroubleshooting: !showSignsWearTroubleshooting,
      height: height
    });
  };

  listItems = acdelco => {
    if (localStorage["items"] === undefined) {
      localStorage["items"] = JSON.stringify([acdelco]);
    } else {
      let localStorageAux = JSON.parse(localStorage["items"]);
      let exist = false;
      for (let item of localStorageAux) {
        if (item.partName === acdelco.partName) {
          if (item.partNumber === acdelco.partNumber) {
            item.quantity = item.quantity + acdelco.quantity;
            localStorage["items"] = JSON.stringify(localStorageAux);
            exist = true;
            break;
          }
        }
      }
      if (exist === false) {
        localStorageAux.push(acdelco);
        localStorage["items"] = JSON.stringify(localStorageAux);
      }
    }
    // this.setState({
    // 	quantity: 1
    // })
    this.changeTotal();
  };

  changeTotal() {
    let quantity = 0;
    if (localStorage["items"]) {
      for (let item of JSON.parse(localStorage["items"])) {
        quantity = quantity + item.quantity;
      }
    } else {
      quantity = 0;
    }
    this.props.updateQuantity(quantity);
  }

  openModal(src) {
    this.setState({ modalIsOpen: true, src: src });
  }

  closeModal() {
    this.setState({ modalIsOpen: false, src: null });
  }
  onImgLoad({ target: img }) {
    this.setState({
      ...this.state,
      width: {
        ...this.state.width,
        [img.src]: img.naturalWidth
      }
    });
  }

  render() {
    const { quantity } = this.state;
    const { height } = this.state;

    let imageSources = [];

    const imageQuantity =
      this.props.acdelco.imageQuantity != null
        ? this.props.acdelco.imageQuantity
        : 0;

    for (let i = 1; i <= imageQuantity; i++) {
      let imageSource =
        "https://acdelcoparts.s3.amazonaws.com/images/" +
        this.props.acdelco.gmPartNumber +
        "_" +
        i +
        ".jpg";
      imageSources.push(imageSource);
    }

    if (imageSources.length > 1 && imageSources[0] == defaultImageSrc) {
      imageSources = imageSources.slice(1, imageSources.length);
    } else if (imageSources.length == 0) {
      imageSources.push(defaultImageSrc);
    }

    const productDescription =
      this.props.acdelco.productDescription != null ? (
        <div className="item__product-description">
          <p>{this.props.acdelco.productDescription}</p>
        </div>
      ) : (
        ""
      );

    const specifications =
      this.props.acdelco.specifications != null &&
      this.state.showSpecifications ? (
        <div>
          <hr />
          <h2>SPECIFICATIONS</h2>
          {this.props.acdelco.specifications.map((specification, index) => {
            return (
              <li>
                <span className="specifications">{specification}</span>
              </li>
            );
          })}
        </div>
      ) : (
        ""
      );

    const faq =
      this.props.acdelco.faq != null && this.state.showFAQ ? (
        <div>
          <hr />
          <h2>FREQUENTLY ASKED QUESTIONS</h2>
          {this.props.acdelco.faq.map((faq, index) => {
            return (
              <div>
                <p className="faq__question">
                  <strong>Q: </strong>
                  {faq.question}
                </p>
                <p className="faq__answer">
                  <strong>A: </strong>
                  {faq.answer}
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        ""
      );

    const featuresAndBenefits =
      this.props.acdelco.featuresAndBenefits != null &&
      this.state.showFeaturesBenefits ? (
        <div className="extras_list">
          <hr />
          <h2>FEATURES AND BENEFITS</h2>
          <ul>
            {this.props.acdelco.featuresAndBenefits.map(
              (featureAndBenefit, index) => {
                return (
                  <li>
                    <span>{featureAndBenefit.featuresAndBenefits}</span>
                  </li>
                );
              }
            )}
          </ul>
        </div>
      ) : (
        ""
      );

    const goodMaintenancePractices =
      this.props.acdelco.goodMaintenancePractices != null &&
      this.state.showGoodMaitenancePractices ? (
        <div className="extras_list">
          <hr />
          <h2>GOOD MAINTENANCE PRACTICES</h2>
          <ul>
            {this.props.acdelco.goodMaintenancePractices.map(
              (goodMaintenancePractice, index) => {
                if (goodMaintenancePractice.header) {
                  return (
                    <p className="list__header">
                      {goodMaintenancePractice.description}
                    </p>
                  );
                } else {
                  return (
                    <li>
                      <span>{goodMaintenancePractice.description}</span>
                    </li>
                  );
                }
              }
            )}
          </ul>
        </div>
      ) : (
        ""
      );

    const warranties =
      this.props.acdelco.warranties != null && this.state.showWarranties ? (
        <div>
          <hr />
          <h2>WARRANTY</h2>
          {this.props.acdelco.warranties.map((warranty, index) => {
            return (
              <li>
                <span className="specifications">{warranty}</span>
              </li>
            );
          })}
        </div>
      ) : (
        ""
      );

    const signsOfWearAndTroubleshooting =
      this.props.acdelco.signsOfWearAndTroubleshooting != null &&
      this.state.showSignsWearTroubleshooting ? (
        <div className="extras_list">
          <hr />
          <h2>SIGNS OF WEAR AND TROUBLESHOOTING</h2>
          <ul>
            {this.props.acdelco.signsOfWearAndTroubleshooting.map(
              (signOfWearAndTroubleshooting, index) => {
                if (signOfWearAndTroubleshooting.header) {
                  return (
                    <p className="list__header">
                      {signOfWearAndTroubleshooting.description}
                    </p>
                  );
                } else {
                  return (
                    <li>
                      <span>{signOfWearAndTroubleshooting.description}</span>
                    </li>
                  );
                }
              }
            )}
          </ul>
        </div>
      ) : (
        ""
      );

    const modal =
      this.state.src !== null ? (
        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalIsOpen}
          contentLabel="Example Modal"
          shouldFocusAfterRender={true}
          style={customStyles}
          onRequestClose={this.closeModal}
          shouldCloseOnOverlayClick={true}
        >
          {/*<img onClick={this.closeModal} onError={this.addDefaultSrc} src={this.state.src} alt={this.defaultImageSrc} />*/}

          <span className="item__modal__close" onClick={this.closeModal}>
            &times;
          </span>

          <Magnifier src={this.state.src} zoomFactor="2" />
        </Modal>
      ) : (
        ""
      );

    const modalAddItem =
      this.state.modalIsOpen == true && this.state.src == null ? (
        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalIsOpen}
          contentLabel="Example Modal"
          shouldFocusAfterRender={true}
          style={customStyles}
          onRequestClose={this.closeModal}
          shouldCloseOnOverlayClick={true}
        >
          <span className="item__modal__close" onClick={this.closeModal}>
            &times;
          </span>

          <h2>Item successfully added to shopping list!</h2>
        </Modal>
      ) : (
        ""
      );

    let gmPartNumbersArray = this.props.acdelco.oems.map((oem) => {
      return oem
    });

    return (
      <div className="item">
        <div className="item__main">
          <div className="item__title__button">
            <div className="item__title">
              <div className="item__title__sub-brand">
                <h2>{this.props.acdelco.marketingDescription}</h2>
                <span>{this.props.acdelco.subBrand}</span>
              </div>
              <div className="item__part__number">
                <div className="item__acdelco__part">
                  <label>ACDelco Part#:</label>
                  <span>{this.props.acdelco.partNumber}</span>
                </div>
                <div className="item__gm__part">
                  <label>GM Part#:</label>
                  {this.props.acdelco.oems.length > 0 ? (
                    <div>
                      <Dropdown
                        items={gmPartNumbersArray}
                      ></Dropdown>
                    </div>
                  ) : (
                    <span>{this.props.acdelco.gmPartNumber}</span>
                  )}                </div>
              </div>
              <div className="item__foot">
                <div className="submit__quantity">
                  {
                    <ul>
                      {
                        <li className="show-on-mobile">
                          <button
                            className="btn--block"
                            onClick={() => {
                              this.props.acdelco[
                                "quantity"
                              ] = this.state.quantity;
                              this.listItems(this.props.acdelco);
                              this.openModal(null);
                            }}
                            block
                          >
                            <FontAwesomeIcon icon={faShoppingCart} />
                            ADD
                          </button>
                          {modalAddItem}
                        </li>
                      }
                    </ul>
                  }
                  {
                    <ul>
                      {
                        <li className="show-on-mobile">
                          <div className="item__counter">
                            <button onClick={this.decrement}>-</button>
                            <span>{quantity}</span>
                            <button onClick={this.increment}>+</button>
                          </div>
                        </li>
                      }
                    </ul>
                  }
                </div>
              </div>
            </div>
            {/* 
						{this.state.showButton ?
							<div className="item__btns__list">
								<button
									className="btn--block"
									onClick={() => this.checkShopList()}
									block>
									<FontAwesomeIcon icon={faShoppingCart} />
									ADD
								</button>
							</div> : */}
            <React.Fragment>
              <div className="item__add__shoplist">
                <div className="item__quantity">
                  <strong>Quantity:</strong>
                  <NumericInput
                    className="quantity__control"
                    value={quantity}
                    onChange={this.updateQuantity}
                    min={1}
                    max={99}
                  />
                </div>
                <div className="item__btns__ok">
                  <button
                    className="btn--block"
                    onClick={() => {
                      this.checkShopList();
                      this.props.acdelco["quantity"] = this.state.quantity;
                      this.listItems(this.props.acdelco);
                    }}
                    block
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      icon={faShoppingCart}
                    />
                    ADD
                  </button>
                </div>
              </div>
            </React.Fragment>
            {/* } */}
          </div>

          <hr />

          <div className="item__image__description">
            <div className="item__aside">
              <div className="item__image">
                <Carousel auto={false} widgets={[IndicatorDots, Buttons]}>
                  {imageSources.map((imageSrc, index) => {
                    return (
                      <div style={divCarousel}>
                        <img
                          onLoad={this.onImgLoad}
                          style={imageCarousel}
                          key={index}
                          onClick={() => this.openModal(imageSrc)}
                          onError={this.addDefaultSrc}
                          src={imageSrc}
                          alt={this.defaultImageSrc}
                        />
                      </div>
                    );
                  })}
                </Carousel>
                {modal}
              </div>

              <div className="item__actions">
                <BlankButton className="item__actions-link">
                  + View Details
                </BlankButton>
              </div>
            </div>

            <div className="item__description">
              <div className="item__label">
                {this.props.acdelco.partName ? (
                  <li>
                    <strong>Part Type: </strong>
                  </li>
                ) : null}

                {this.props.acdelco.qty ? (
                  <li>
                    <strong>Quantity: </strong>
                  </li>
                ) : null}

                {this.props.acdelco.position ? (
                  <li>
                    <strong>Position: </strong>
                  </li>
                ) : null}

                {this.props.acdelco.subModel ? (
                  <li>
                    <strong>Sub-Model: </strong>
                  </li>
                ) : null}

                {this.props.acdelco.notesEngine ? (
                  <li>
                    <strong>Engine: </strong>
                  </li>
                ) : null}

                {this.props.acdelco.notesTransmission ? (
                  <li>
                    <strong>Transmission: </strong>
                  </li>
                ) : null}

                {this.props.acdelco.notesFuel ? (
                  <li>
                    <strong>Fuel: </strong>
                  </li>
                ) : null}

                {this.props.acdelco.notesBrakes ? (
                  <li>
                    <strong>Brakes: </strong>
                  </li>
                ) : null}

                {this.props.acdelco.notesBody ? (
                  <li>
                    <strong>Body: </strong>
                  </li>
                ) : null}

                {this.props.acdelco.notes !== "###" ? (
                  <li>
                    <strong>Notes: </strong>
                  </li>
                ) : null}
              </div>

              <div className="item__info">
                {this.props.acdelco.partName ? (
                  <li>{this.checkInfo(this.props.acdelco.partName)}</li>
                ) : null}

                {this.props.acdelco.qty ? (
                  <li>{this.checkInfo(this.props.acdelco.qty)}</li>
                ) : null}

                {this.props.acdelco.position ? (
                  <li>{this.checkInfo(this.props.acdelco.position)}</li>
                ) : null}

                {this.props.acdelco.subModel ? (
                  <li>{this.checkInfo(this.props.acdelco.subModel)}</li>
                ) : null}

                {this.props.acdelco.notesEngine ? (
                  <li>{this.checkInfo(this.props.acdelco.notesEngine)}</li>
                ) : null}

                {this.props.acdelco.notesTransmission ? (
                  <li>
                    {this.checkInfo(this.props.acdelco.notesTransmission)}
                  </li>
                ) : null}

                {this.props.acdelco.notesFuel ? (
                  <li>{this.checkInfo(this.props.acdelco.notesFuel)}</li>
                ) : null}

                {this.props.acdelco.notesBrakes ? (
                  <li>{this.checkInfo(this.props.acdelco.notesBrakes)}</li>
                ) : null}

                {this.props.acdelco.notesBody ? (
                  <li>{this.checkInfo(this.props.acdelco.notesBody)}</li>
                ) : null}

                {this.props.acdelco.notes !== "###" ? (
                  <li>{this.checkInfoNotes(this.props.acdelco.notes)}</li>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <hr />
        {productDescription}

        <div className="item__extras">
          <div className="item__extras__headers">
            <button
              className={
                this.props.acdelco.specifications != "undefined" &&
                this.props.acdelco.specifications != null &&
                this.props.acdelco.specifications.length > 0
                  ? ""
                  : "empty"
              }
              onClick={this.handleSpecifications}
            >
              <strong>+ Specifications</strong>
            </button>
            <button
              className={
                this.props.acdelco.faq != "undefined" &&
                this.props.acdelco.faq != null &&
                this.props.acdelco.faq.length > 0
                  ? "item__faq"
                  : "item__faq__empty"
              }
              onClick={this.handleFaq}
            >
              <strong>+ FAQ</strong>
            </button>
            <button
              className={
                this.props.acdelco.featuresAndBenefits != "undefined" &&
                this.props.acdelco.featuresAndBenefits != null &&
                this.props.acdelco.featuresAndBenefits.length > 0
                  ? ""
                  : "empty"
              }
              onClick={this.handleFeaturesAndBenefits}
            >
              <strong>+ Features & Benefits</strong>
            </button>
            <button
              className={
                this.props.acdelco.goodMaintenancePractices != "undefined" &&
                this.props.acdelco.goodMaintenancePractices != null &&
                this.props.acdelco.goodMaintenancePractices.length > 0
                  ? ""
                  : "empty"
              }
              onClick={this.handleGoodMaintenancePractices}
            >
              <strong>+ Maintenance</strong>
            </button>
            <button
              className={
                this.props.acdelco.warranties != "undefined" &&
                this.props.acdelco.warranties != null &&
                this.props.acdelco.warranties.length > 0
                  ? ""
                  : "empty"
              }
              onClick={this.handleWarranties}
            >
              <strong>+ Warranty</strong>
            </button>
            <button
              className={
                this.props.acdelco.signsOfWearAndTroubleshooting !=
                  "undefined" &&
                this.props.acdelco.signsOfWearAndTroubleshooting != null &&
                this.props.acdelco.signsOfWearAndTroubleshooting.length > 0
                  ? ""
                  : "empty"
              }
              onClick={this.handleSignsOfWearAndTroubleshooting}
            >
              <strong>+ Wear & Troubleshooting</strong>
            </button>
          </div>
          <div>
            <AnimateHeight height={height}>
              {specifications}
              {faq}
              {featuresAndBenefits}
              {goodMaintenancePractices}
              {warranties}
              {signsOfWearAndTroubleshooting}
            </AnimateHeight>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  itemsQuantity: store.itemShoplist.itemsQuantity
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateQuantity }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ElasticItem);
