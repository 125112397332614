import React, { Component } from "react";
import { ADMIN_API } from "../endpoints/endpoints";
import Loader from "../components/common/loader";
import Pagination from "../components/items/pagination";
import ExcelExport from '../components/excel-exporter/excel-export'
import AdminCart from './admin-cart'

class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCarts: [],
      currentPage: null,
      seeItemsMode: undefined,
      showParts: false,
      cartId: null,
      cartItems: [],
      allCartItems: [],
      totalPages: null,
      isLoading: true,
      carts: []
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    const query = ADMIN_API.defaults.baseURL + "/carts";
    ADMIN_API.get(query).then(response => {

      const carts = response.data.contents
      let allCartItems = carts.map((cart) => {
        return cart.cart.cartItems.map((cartItem) => {
          return {
            ...cartItem,
            shopcartId: cart.cart.id
          }
        });
      });
      allCartItems = [].concat.apply([], allCartItems);

      this.setState({
        carts: carts,
        currentCarts: [],
        allCartItems: [...allCartItems],
        isLoading: false
      });
    });
  }

  onPageChanged = data => {
    window.scrollTo(0, 0);

    let { carts } = this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentCarts = carts.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentCarts, totalPages });
  };

  renderTableHeader = () => {
    return (
      <tr>
        <th>{"ID"}</th>
        <th>{"Date"}</th>
        <th>{"Name"}</th>
        <th>{"Country"}</th>
        <th>{"City"}</th>
        <th>{"Telephone"}</th>
        <th>{"eMail"}</th>
        <th>{"Parts"}</th>
      </tr>
    );
  };

  seeItems = (cartId, cartItems) => {

    // Add cartId property to cartItems to export that data in spreadsheet
    cartItems = cartItems.map((cartItem) => {
      return {
        ...cartItem,
        shopcartId: cartId
      }
    });


    this.setState({
      showParts: true,
      cartId: cartId,
      cartItems: [...cartItems]
    });
  };

  // seeItems = cartItems => {
  //   this.props.history.replace({
  //     pathname: "/adm-cart",
  //     state: {
  //       seeItemsMode: true,
  //       cartItems: [...cartItems]
  //     }
  //   });
  // };

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const { isLoading, currentCarts, carts, currentPage } = this.state;
    if (isLoading) {
      return <Loader />;
    }

    const parts =
      this.state.showParts ? (
        <AdminCart
          cartId={this.state.cartId}
          cartItems={this.state.cartItems}
          downloadParts={
            <div className="inner">
              <ExcelExport buttonText={'Download Parts'} innerField={'cartItems'} dataSet={this.state.allCartItems} fields={['id', 'shopcartId', 'partNumber', 'gmPartNumber', 'partName', 'quantity']} fileName={'PARTS'} />
            </div>
          }
        />
      ) : (
          ""
        );

    return (
      <div>
        <h1 id="titleDataAdmin">Shopping carts</h1>
        <table id="adm" className="dataAdmin">
          <tbody>
            {this.renderTableHeader()}
            {currentCarts.map((cart, index) => {
              let date = new Date(cart.cart.dataCriacao);
              return (
                <tr key={cart.cart.id}>
                  <td>{cart.cart.id}</td>
                  <td>{date.toLocaleDateString("en-GB")}</td>
                  <td>{cart.cart.name}</td>
                  <td>{cart.cart.country}</td>
                  <td>{cart.cart.city}</td>
                  <td>{cart.cart.telephone}</td>
                  <td>{cart.cart.email}</td>
                  <td>
                    <button onClick={() => this.seeItems(cart.cart.id, cart.cart.cartItems)}>
                      See Items
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="table-footer">
          <div className="pagination-bottom">
            <div className="left">
              <h2>
                <strong className="text-secondary">Pages</strong>
              </h2>
            </div>
            <div className="right">
              <Pagination
                totalRecords={carts.length}
                pageLimit={10}
                pageNeighbours={1}
                onPageChanged={this.onPageChanged}
                currentPage={currentPage}
              />
            </div>
            <div className="inner">
              <ExcelExport buttonText={'Download Shopping Carts'} innerField={'cart'} dataSet={carts} fields={['id', 'dataCriacao', 'name', 'country', 'city', 'telephone', 'email']} fileName={'SHOPPING CARTS'} />
            </div>
            <div>

            </div>
          </div>
        </div>

        {parts}

      </div>
    );
  }
}

export default AdminPage;
