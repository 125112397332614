import { handleActions } from 'redux-actions';
import { changeSearchBoxToggle, changeFiltersToggle, changeUniversalDropdownToggle, closeSearchToggles } from './actions';

const initialState = {
	isSearchBoxVisible: false,
	isBrowseFiltersVisible: false,
	isUniversalDropdownVisible: false
};

const searchToggles = handleActions({
	[changeSearchBoxToggle]: (state, {payload}) => ({
		...state, 
		isSearchBoxVisible: payload
	}),

	[changeFiltersToggle]: (state, {payload}) => ({
		...state, 
		isBrowseFiltersVisible: payload
	}),

	[changeUniversalDropdownToggle]: (state, {payload}) => ({
		...state, 
		isUniversalDropdownVisible: payload
	}),

	[closeSearchToggles]: (state) => ({
		...initialState
	})
}, initialState);

export default searchToggles;