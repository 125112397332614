import React, { Component } from 'react';

class SubCategoryButton extends Component {

    render() {
        return (
            <li className={this.props.selectedSubCategory != null && this.props.selectedSubCategory.subCategoryName === this.props.subCategory ? "selected" : ""}
                onClick={()=>{this.props.handleSubCategoryClick(); this.props.handleFilterResults();}}><a>{this.props.subCategory}</a></li>
        )
    }
}

export default SubCategoryButton;
