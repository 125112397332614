import React from "react";
import ReactExport from "react-export-excel";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExcelExport extends React.Component {
  render() {
    let { fileName, dataSet, fields, innerField } = this.props;

    let editedDataSet = dataSet.reduce(function (acc = [], currentItem) {
      let newElement = fields.reduce(function (element = {}, currentField) {
        if (fields.includes(`${currentField}`)) {
          if (innerField == 'cartItems') {
            element[`${currentField}`] =
              currentItem[`${currentField}`];
          } else {
            element[`${currentField}`] =
              currentItem[`${innerField}`][`${currentField}`];
          }
        }
        return element;
      }, {});
      acc.push(newElement);
      return acc;
    }, []);

    return (
      <ExcelFile filename={fileName} element={<button style={{color: "#525258"}}>{this.props.buttonText}</button>}>
        <ExcelSheet data={editedDataSet} name={fileName}>
          {fields.map(field => {
            if (field === "dataCriacao") {
              return (
                <ExcelColumn
                  label={'Date'}
                  value={creationDate => {
                    let date = new Date(creationDate.dataCriacao);
                    return date.toLocaleDateString("en-GB");
                  }}
                />
              );
            } else {
              return <ExcelColumn label={field} value={field} />;
            }
          })}
        </ExcelSheet>
      </ExcelFile>
    );
  }
}
