// This must be the first line in src/index.js
import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, compose } from 'redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga'
import createHistory from 'history/createBrowserHistory'

import './assets/scss/_style.scss';

import rootReducer from './store/reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, {}, composeEnhancers());

ReactGA.initialize('UA-144017057-1');

const history = createHistory()
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});


ReactDOM.render(
	<Provider store={store}>
		<App store={store}/>
	</Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
