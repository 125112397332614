import React, { Component } from 'react';
import ItemsList from '../components/items/items-list';
import Layout from '../components/layout/layout';
import Loader from '../components/common/loader';
import SearchPageFilter from '../components/filters/search-page-filter';
import { ACDELCO_API } from '../endpoints/endpoints';


class SearchResults extends Component {

	constructor() {
		super();
		this.state = {
			isLoading: false,
			years: [],
			models: [],
			partNames: []
		}
	}

	componentDidMount() {
		this.getParts();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps !== this.props) {
			this.getParts();
		}
	}

	getParts = () => {
		const partNumber = this.props.location.term != undefined ? this.props.location.term : localStorage['partNumber']
		const queryKey = '/search-results'
		const searchQuery = 'partNumber=' + partNumber
		const query = ACDELCO_API.defaults.baseURL + queryKey + '?' + searchQuery;

		ACDELCO_API.get(query)
			.then(response => {
				let listParts = []
				if (response.data != "" && response.data.contents.length > 0) {
					response.data.contents.map((acdelco, index) => {
						listParts.push({
							acdelco: acdelco,
							acdelcoModel: acdelco.acdelco.year + " / " + acdelco.acdelco.make + " / " + acdelco.acdelco.model
						})
					})
					listParts.sort(this.getOrderedListOfParts)
				}

				this.setState({
					acdelcos: response.data.contents,
					listParts: listParts,
					years: [],
					models: [],
					partNames: [],
					partNumber: partNumber
				})
			})
	}

	getOrderedListOfParts = (part1, part2) => {
		if (part1.acdelcoModel > part2.acdelcoModel) {
			return - 1
		} else if (part1.acdelcoModel < part2.acdelcoModel) {
			return 1
		}
		return 0
	}

	handleFilterChange = (years, models, partNames) => {

		let listParts = []
		if (years.length == 0 && models.length == 0 && partNames.length == 0) {
			this.state.acdelcos.map((acdelco, index) => {
				listParts.push({
					acdelco: acdelco,
					acdelcoModel: acdelco.acdelco.year + " / " + acdelco.acdelco.make + " / " + acdelco.acdelco.model
				})
			})
		} else {
			this.state.acdelcos.map((acdelco, index) => {
				let part = {
					acdelco: acdelco,
					acdelcoModel: acdelco.acdelco.year + " / " + acdelco.acdelco.make + " / " + acdelco.acdelco.model
				}

				//Year, Model and PartName
				if (years.length != 0 && models.length != 0 && partNames.length != 0) {
					if (years.includes(acdelco.acdelco.year) && (!listParts.includes(part)) && (models.includes(acdelco.acdelco.make + " " + acdelco.acdelco.model)) && partNames.includes(acdelco.acdelco.partName)) {
						listParts.push(part)
					}
				}
				//Year and Model
				else if (years.length != 0 && models.length != 0 && partNames.length == 0) {
					if (years.includes(acdelco.acdelco.year) && (!listParts.includes(part)) && (models.includes(acdelco.acdelco.make + " " + acdelco.acdelco.model))) {
						listParts.push(part)
					}
				}
				//Year and PartName
				else if (years.length != 0 && models.length == 0 && partNames.length != 0) {
					if (years.includes(acdelco.acdelco.year) && partNames.includes(acdelco.acdelco.partName)) {
						listParts.push(part)
					}
				}
				//Model and PartName
				else if (years.length == 0 && models.length != 0 && partNames.length != 0) {
					if ((!listParts.includes(part)) && (models.includes(acdelco.acdelco.make + " " + acdelco.acdelco.model)) && partNames.includes(acdelco.acdelco.partName)) {
						listParts.push(part)
					}
				}
				//Year
				else if (years.length != 0 && years.includes(acdelco.acdelco.year)) {
					listParts.push(part)
				}
				//Model
				else if (models.length != 0 && (!listParts.includes(part)) && (models.includes(acdelco.acdelco.make + " " + acdelco.acdelco.model))) {
					listParts.push(part)
				}
				//Partname
				else if (partNames.length != 0 && partNames.includes(acdelco.acdelco.partName)) {
					listParts.push(part)
				}
			})
		}

		listParts.sort(this.getOrderedListOfParts)

		this.setState({
			models: models,
			years: years,
			partNames: partNames,
			listParts: listParts
		})
	}

	render() {

		let yearsAndModelsFilter;
		let parts;

		const filters = {
			partNumber: {
				name: this.props.location.term
			}
		}

		if (this.state.partNumber != localStorage['partNumber']) {
			yearsAndModelsFilter = <Loader />
			parts = <Loader />
		} else if (this.state.listParts.length > 0 || this.state.years.length > 0 || 
			this.state.models.length > 0 || this.state.partNames.length > 0) {

			yearsAndModelsFilter = <SearchPageFilter
				acdelcos={this.state.acdelcos}
				years={this.state.years}
				models={this.state.models}
				partNames={this.state.partNames}
				filterChanged={this.handleFilterChange}
			/>

			parts = <div>
				<h2 className="title title-filter-big">Search results:</h2>
				<ItemsList
					key={this.props.term}
					queryKey='/search-results'
					filters={filters}
					acdelcos={this.state.listParts}
					searchByPartNumber={true}
				/>
			</div>
		} else {
			parts = 
			<div className="no-parts">
				<h2 className="no-parts-title">NO PARTS WERE FOUND MATCHING THE SEARCH REQUESTS</h2>
				<span className="search-tips"> SEARCH TIPS: </span>
				<ul className="no-parts-list">
					<li>In order to have consistent results, remember to be specific when searching.</li>
					<li>You can type several terms for the search, separated by a space, <br/>
						like "2018 Corvette Oil Filter"</li>
					<li>Search results are limited to 500 parts.</li>
				</ul>
			</div>
		}

		return (
			<div className="main">
				<div className="shell">
					<Layout
						sidebarContent={yearsAndModelsFilter}
						mainContent={parts}
					/>
				</div>
			</div>
		)
	}

};

export default SearchResults;