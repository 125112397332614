import React, { Component } from 'react';
// import { HashRouter as Router, Route } from 'react-router-dom';
import { ACDELCO_API } from '../endpoints/endpoints';

class HealthCheck extends Component {
	
	constructor() {
		super();
		this.state = {
			health: null
		}
	}

	componentDidMount() {

		const query = ACDELCO_API.defaults.baseURL + '/health-check';
		ACDELCO_API.get(query)
			.then(response => {
				this.setState({
					health: response.data,
					isLoading: false
				})
			})
	}
	
	render() {
	  
		return (
					<p>Health-Check: {this.state.health}</p>
		);
  }
}

export default HealthCheck;
