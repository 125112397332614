import React, { Component } from 'react';

import { connect } from 'react-redux';
import { closeSearchToggles } from '../../store/state/search-toggles/actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

import { withRouter } from 'react-router-dom'
import { changeSearchBoxToggle } from '../../store/state/search-toggles/actions';


class SearchBox extends Component {
	constructor() {
		super();

		this.state = {
			term: ''
		};
	}

	componentDidUpdate(){
		
		if(window.innerWidth <= 1000){
			if(this.props.active){
				if(this.props.location.pathname !== '/'){
					this.props.changeSearchBoxToggle(false);
				}
			}
		}
	}

	onInputChange = (event) => {
		this.changeTerm(event);
	}

	changeTerm(event) {
		this.setState({
			term: event.target.value
		});
	}

	resetTerm() {
		this.setState({
			term: ''
		});
	}

	handleFormSubmit = (event) => {
		
		//Save partNumber to be used again in case of refresh page. This variable will be used in file search-results.js in function getParts()
		localStorage['partNumber'] = this.state.term

		event.preventDefault();
		if(this.state.term === '') {
			return;
		}
		
		if (this.props.location.pathname !== '/search-results') {
			this.props.history.push({
				pathname: '/search-results',
				term: this.state.term
			});
		} else {
			this.props.history.replace({
				pathname: '/search-results',
				term: this.state.term
			});
		}

		if(document.getElementById("search__close")){
			if(document.getElementById("search__close").clientHeight !== 0){
				this.handleCloseButtonClick();
			}
		}
		
	}

	handleCloseButtonClick = () => {
		this.resetTerm();
		
		this.props.closeSearchToggles();	
	}

	render() {
		if(!this.props.active) {
			return null;
		}

		return (
			<div className="search">
				<form action="?" method="post" onSubmit={this.handleFormSubmit}>
					<div className="search__inner">
						<input type="search"
							id="search-field"
							placeholder="Type year, model, part name and part number"
							className="search__field"
							value={this.state.term}
							onChange={this.onInputChange}
						/>

						<button type="submit" className="search__btn">
							<FontAwesomeIcon icon={faSearch} />
						</button>

						<button type="button" className="search__close" id="search__close" onClick={this.handleCloseButtonClick}>
							<FontAwesomeIcon icon={faTimes} />
						</button>
					</div>
				</form>
			</div>
		)
	}
}

export default withRouter(connect(
	null,
	{
		changeSearchBoxToggle,
		closeSearchToggles
	}
)(SearchBox));