import React, { Component } from 'react';

class Article extends Component {
	render() {
		return (
			<article className="article">
			<a href={this.props.link} rel="noopener noreferrer" target="_blank" style={{textDecoration: 'none'}}>
				<div className="article__image" style={{ backgroundImage: `url(${this.props.imageSrc})` }}></div>

					<h3 className="article__title">
						<em>{ this.props.title }</em>
					</h3>

				<div className="article__entry">
					<p>{ this.props.entry }</p>
				</div>
			</a>
			</article>
		)
	}
}

export default Article;