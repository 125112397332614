import React, { Component } from 'react';

class Articles extends Component {
	render() {
		return (
			<div className="articles">
				<div className="shell">
					<div className="articles__inner">
						{ this.props.children }
					</div>
				</div>
			</div>
		)
	}
}

export default Articles;