import React, { Component } from 'react';

import Layout from '../components/layout/layout';
import Categories from '../components/categories/categories';
import ArticleSingle from '../components/article/article-single';
import CategorySelection from '../components/category-selection/category-selection';
import ItemsList from '../components/items/items-list';
import FilterResults from '../components/filter-results/filter-results';
import AnimateHeight from 'react-animate-height';


class CategoriesPage extends Component {
	constructor() {
		super();
		this.state = {
			selectedCategory: null,
			selectedSubCategory: null,
			renderCategories: true,
			showFilterResultsButton: false,
			acdelcos: [],
			filteredAcdelcos: [],
			showFilterResults: false,
			height: 'auto'
		}
	}

	componentWillMount() {
		if (localStorage['category'] !== undefined && localStorage['category'] !== null) {
			this.setState({
				selectedCategory: localStorage['category']
			})
		}
		if (localStorage['subCategory'] !== undefined && localStorage['subCategory'] !== null) {
			this.setState({
				selectedSubCategory: JSON.parse(localStorage['subCategory'])
			})
		}
	}

	changeCategory = (category) => {
		this.setState({
			selectedCategory: category,
			selectedSubCategory: null
		})
		localStorage['category'] = category
	}

	changeSubCategory = (subCategory) => {
		this.setState({
			selectedSubCategory: subCategory,
			showFilterResultsButton: false,
			acdelcos: [],
			filteredAcdelcos: []
		})
		localStorage['subCategory'] = JSON.stringify(subCategory)
	}

	showFilterResults = (acdelcos, filteredAcdelcos) => {
		if (acdelcos.length > 0) {
			this.setState({
				showFilterResultsButton: true,
				acdelcos: [...acdelcos],
				filteredAcdelcos: [...filteredAcdelcos]
			})
		}
	}

	onFilterResultsChanged = (acdelcos, filteredAcdelcos) => {
		this.setState({
			acdelcos: [...acdelcos],
			filteredAcdelcos: filteredAcdelcos
		})
	}

	handleFilterResults = () => {
		this.setState({
			showFilterResults: true
		});
	}

	render() {

		const { height } = this.state;

		let filterResults = (this.state.acdelcos.length > 0 && this.state.showFilterResults) ?
			<FilterResults
				acdelcos={this.state.acdelcos}
				handleFilterResults={this.onFilterResultsChanged}
			/>
			: null;

		const categories = (this.state.renderCategories) ?
			<Categories
				handleFilterResults={() => this.handleFilterResults()}
				selectedCategoryHandler={function (category) {
					self.changeCategory(category);
				}}
				selectedSubCategoryHandler={function (subCategory) {
					self.changeSubCategory(subCategory);
				}}
				categories={JSON.parse(localStorage.getItem('categories'))}
				subCategories={JSON.parse(localStorage.getItem('subCategories'))}
				selectedSubCategory={this.state.selectedSubCategory}
			/>
			: null;

		let self = this;
		let filters = JSON.parse(localStorage.getItem('filters'));
		let article;
		if (this.state.selectedCategory === null || this.state.selectedSubCategory === null) {
			filterResults = null;
			article =
				<ArticleSingle
					title="ACDelco Stands for Quality"

					entry1="Get the performance you need with ACDelco products."

					entry2="Dependable. Durable. Engineered and tested to the highest standards. Find the right ACDelco Parts for your vehicle from among our extensive product offerings. Browse our parts categories on the left."

					entry3="ACDelco strives to make this information as comprehensive and factual as possible. We reserve the right, however, to make changes at any time, without notice. Since some information may not have been updated since the time of publication, please contact us, or get in touch with one of our ACDelco distributors for complete details."

					note="Select a Category/Sub-Category to view parts"

					imageSrc={require('../assets/images/parts.jpg').default}
				/>;
		} else {
			filters["category"] = this.state.selectedCategory;
			filters["subCategory"] = this.state.selectedSubCategory;
			article =
				<ItemsList
					key={filters.subCategory}
					filters={filters}
					queryKey="/acdelcos"
					showFilterResults={this.showFilterResults}
					showFilterResultsButton={this.state.showFilterResultsButton}
					acdelcos={this.state.acdelcos}
					filteredAcdelcos={this.state.filteredAcdelcos}
				/>;
		}

		return (

			<div className="main">
				<div className="shell">
					<div className="categories__header">
						<CategorySelection
							year={filters.year.name}
							make={filters.make.name}
							model={filters.model.name}
							category={this.state.selectedCategory}
							subCategory={this.state.selectedSubCategory}
						/>
					</div>

					<Layout
						sidebarContent={categories}

						filterResults={<AnimateHeight height={height}>
							{filterResults}
						</AnimateHeight>}
						mainContent={(
							article
						)}
					/>
				</div>
			</div>
		)
	}

};

export default CategoriesPage;