import React, { Component } from 'react';
import { Auth, Cache } from 'aws-amplify';
import { Redirect, Route } from 'react-router-dom';
import AdminPage from './admin-page';
import Modal from "react-modal";
import Recaptcha from "react-google-recaptcha";
const recaptchaRef = React.createRef();

const customStyles = {
    overlay: {
      zIndex: "100"
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "100",
      border: 0,
      padding: 0,
      overflow: "hidden"
    }
  };

class CognitoReactSignInForm extends Component {
    state = {
        username: '',
        password: '',
        signedIn: false,
        isSigningIn: false,
        isSigningOut: false,
        tokenId: '',
        refreshToken: '',
        captchaVerified: false,
        loginError: false,
        message: '',
        messageIsOpen: false
    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.handleLogout = this.handleLogout.bind(this);
    }

    handleMessage() {
        this.setState({ messageIsOpen: !this.state.messageIsOpen });
      }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { signedIn, username, password } = this.state;

        if (!signedIn) {
            this.setState({ isSigningIn: true });
            Auth.signIn({
                username,
                password
            }).then((cognitoUser) => {
                // console.log('Signed In!');
                // console.log("CognitoUser:");
                // console.log(cognitoUser);

                Auth.currentSession()
                    .then((userSession) => {
                        // console.log("Got user currentSession:");
                        // console.log(userSession);
                        this.setState({
                            signedIn: true,
                            isSigningIn: false,
                            tokenId: userSession.idToken.jwtToken,
                            refreshToken: userSession.refreshToken.token
                        });
                    })
                    .catch((err) => {
                        this.setState({ isSigningIn: false });
                    });

            }).catch((err) => {
                this.setState({ 
                    isSigningIn: false,
                    loginError: true,
                    message: err.message,
                    messageIsOpen: !this.state.messageIsOpen
                });
            });
        }
    }

    changeAuthStorageConfiguration(e) {
        const shouldRememberUser = e.target.checked;
        if (shouldRememberUser) {
            const localStorageCache = Cache.createInstance({
                keyPrefix: "localStorageAuthCache",
                storage: window.localStorage
            });

            Auth.configure({
                storage: localStorageCache
            });
        } else {
            const sessionStorageCache = Cache.createInstance({
                keyPrefix: "sessionAuthCache",
                storage: window.sessionStorage
            });

            Auth.configure({
                storage: sessionStorageCache
            });
        }
    }

    // handleLogout() {
    //     if (this.state.signedIn) {
    //         this.setState({ isSigningOut: true });
    //         Auth.signOut()
    //             .then((data) => {
    //                 this.setState({ 
    //                     signedIn: false, 
    //                     isSigningOut: false,
    //                     tokenId: '',
    //                     refreshToken: ''
    //                 });
    //                 console.log(data);
    //             })
    //             .catch((err) => {
    //                 this.setState({ isSigningOut: false });
    //                 console.log(err);
    //             });
    //     }
    // }

    componentDidMount() {
        this.setState({ isSigningIn: true });
        Auth.currentSession()
            .then((userSession) => {
                // console.log("Got user currentSession!");
                // console.log(userSession);
                this.setState({
                    signedIn: true,
                    isSigningIn: false,
                    tokenId: userSession.idToken.jwtToken,
                    refreshToken: userSession.refreshToken.token
                });
            })
            .catch((err) => {
                this.setState({ isSigningIn: false });
                // console.log(err)
            });
    }

    render() {

        if (this.state.signedIn) {

            return (
                <Redirect to={"/adm"}
                />
            )

            // return (<div>
            //     <div><b>Your tokenId:</b></div><div>{this.state.tokenId}</div>
            //     <div><b>Your refreshToken:</b></div><div>{this.state.refreshToken}</div>
            //     <button className="btn-toggle" onClick={this.handleLogout} className="btn btn-danger">Sair</button>
            // </div>)
        }

        const modalMessage =
            this.state.src !== null ? (
                <Modal
                    ariaHideApp={false}
                    isOpen={this.state.messageIsOpen}
                    shouldFocusAfterRender={true}
                    style={customStyles}
                    onRequestClose={this.handleMessage}
                    shouldCloseOnOverlayClick={true}
                >
                    <div className="message_box">
                        <span>{this.state.message}</span>
                        <button className="btn--block" onClick={() => this.handleMessage()}>
                            CLOSE
                        </button>
                    </div>
                </Modal>
            ) : (
                    ""
                );

        return (

            <div className="shoplist__main">

                <div className="shoplist__form" style={{ marginTop: "80px" }}>
                    <form onSubmit={this.handleSubmit}>
                        <div className="container">
                            <div className="form__input">
                                <div className="form__item">
                                    <strong name="email">Username:</strong>
                                    <input className="form-control" type="text" name="username" id="usernameSignInInput" onChange={this.handleChange} />
                                </div>
                                <div className="form__item">
                                    <strong name="email">Password:</strong>
                                    <input className="form-control" type="password" name="password" id="passwordSignInInput" onChange={this.handleChange} />
                                </div>
                                {/* <div className="form__item form-check">
                <input defaultChecked type="checkbox" className="form-check-input" id="rememberMeSignInInput" onChange={this.changeAuthStorageConfiguration} />
                <label className="form-check-label" htmlFor="rememberMeSignInInput">Lembrar-se de mim</label>
            </div> */}
                            </div>
                            <div className="shoplist__captcha">
                                <Recaptcha
                                    ref={recaptchaRef}
                                    //prod
                                    sitekey="6Lf4OZkUAAAAAPYiEx_YtLIs9foYDwTlSnpHQujB"
                                    // sitekey='6LdrspgUAAAAABlzVjVLmFH8IFanLKyDTfrd1JC9'
                                    onChange={() => {
                                        this.setState({
                                            captchaVerified: true
                                        });
                                    }}
                                />
                            </div>
                            <div className="form__button" style={{ margin: "20px" }}>
                                <button disabled={this.state.isSigningIn || !this.state.captchaVerified} type="submit" className="btn--block" style={{ margin: "0px" }}>LOGIN</button>
                            </div>
                        </div>
                        {modalMessage}
                    </form>
                </div>
            </div>

        )
    }
}

export default CognitoReactSignInForm;