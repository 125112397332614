import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeFiltersToggle } from '../../store/state/search-toggles/actions';
import { withRouter } from 'react-router-dom'

class CategorySelection extends Component {

	openBrowseFilters(year, make){
		this.props.history.replace({
			state: {
				year: year,
				make: make
			}
		});
		this.props.changeFiltersToggle(true);
	}

	render() {

		return (
			<div className="category-selection">
				<span className="category-selection__title">Selected Vehicle:</span>
				<div>
					<strong className="category-selection__year" onClick={()=>this.openBrowseFilters(this.props.year,null)}>{this.props.year}</strong>
					<strong className="category-selection__make" onClick={()=>this.openBrowseFilters(this.props.year,this.props.make)}>{this.props.make}</strong>
					<strong className="category-selection__model">{this.props.model}</strong>
					{this.props.category !== null ?
						<strong className="category-selection__category">{this.props.category}</strong>
					: null}
					{this.props.subCategory !== null ?
						<strong className="category-selection__subcategory">{this.props.subCategory.subCategoryName}</strong>
					: null}
				</div>
			</div>
		)

	}
}

export default withRouter(connect(
	(state) => ({
		searchToggles: state.searchToggles
	}),
	{
		changeFiltersToggle
	}
)(CategorySelection));