import React, { Component } from 'react';

import Sidebar from './sidebar';
import Content from './content';

class Layout extends Component {
	render() {
		return (
			<div className="container">
				<Sidebar>
					{ this.props.filterResults }
					{ this.props.sidebarContent}
				</Sidebar>
								
				<Content>
					{ this.props.mainContent}
				</Content>
			</div>
		)
	}
}

export default Layout;