import React, { Component } from 'react';
import { IMPORT_API } from '../endpoints/endpoints';

const formStyle = {
    margin: '10px 0px 6px 6px'
}

class UploadData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null
        }
    }

    onChange = (e) => {
        this.setState({ file: e.target.files[0] })
    }

    onFormSubmit = (e, endpoint, method) => {
        e.preventDefault() // Stop form submit

        const url = IMPORT_API.defaults.baseURL + endpoint;
        if (method === 'post') {
            const formData = new FormData();
            formData.append('file', this.state.file)

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            IMPORT_API.post(url, formData, config).then((response) => {
                console.log(response.data);
            })
        }else if(method === 'get'){
            IMPORT_API.get(url).then((response) => { 
                console.log(response.data);
            })
        }
    }

    render() {
        return (
            <div>
                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/importCategoriesSubcategories', 'post')}>
                    <h2>Import Categories</h2>
                    <input type="file" onChange={this.onChange} />
                    <button type="submit">Submit</button>
                </form>

                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/importImages', 'post')}>
                    <h2>Import Images</h2>
                    <input type="file" onChange={this.onChange} />
                    <button type="submit">Submit</button>
                </form>

                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/importVehicles', 'post')}>
                    <h2>Import Vehicles</h2>
                    <input type="file" onChange={this.onChange} />
                    <button type="submit">Submit</button>
                </form>

                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/importAcdelcoPartExtras', 'post')}>
                    <h2>Import Parts Acdelco Extras</h2>
                    <input type="file" onChange={this.onChange} />
                    <button type="submit">Submit</button>
                </form>

                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/importUniversalParts', 'post')}>
                    <h2>Import Universal Parts</h2>
                    <input type="file" onChange={this.onChange} />
                    <button type="submit">Submit</button>
                </form>

                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/importAcdelcoPart', 'post')}>
                    <h2>Import Parts Acdelco</h2>
                    <input type="file" onChange={this.onChange} />
                    <button type="submit">Submit</button>
                </form>

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/importGMPartNumbersxOems', 'post')}>
                    <h2>Import GM Part Numbers x OEMs</h2>
                    <input type="file" onChange={this.onChange} />
                    <button type="submit">Submit</button>
                </form>

                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/addImagesAcdelco', 'get')}>
                    <h2>Add Images Acdelco</h2>
                    <button type="submit">Submit</button>
                </form>

                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/addProductDescriptionAcdelco', 'get')}>
                    <h2>Add Product Description Acdelco</h2>
                    <button type="submit">Submit</button>
                </form>

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/addPnToOemAcdelco', 'get')}>
                    <h2>Add Part Number to OEM Acdelco</h2>
                    <button type="submit">Submit</button>
                </form>

                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/addSpecsAcdelco', 'get')}>
                    <h2>Add Specs Acdelco</h2>
                    <button type="submit">Submit</button>
                </form>

                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/addFaqsAcdelco', 'get')}>
                    <h2>Add Faqs Acdelco</h2>
                    <button type="submit">Submit</button>
                </form>

                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/addFabsAcdelco', 'get')}>
                    <h2>Add Fabs Acdelco</h2>
                    <button type="submit">Submit</button>
                </form>

                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/addGmpsAcdelco', 'get')}>
                    <h2>Add Gmps Acdelco</h2>
                    <button type="submit">Submit</button>
                </form>

                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/addWarrantiesAcdelco', 'get')}>
                    <h2>Add Warranties Acdelco</h2>
                    <button type="submit">Submit</button>
                </form>

                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/addSwtsAcdelco', 'get')}>
                    <h2>Add Swts Acdelco</h2>
                    <button type="submit">Submit</button>
                </form>

                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/addGmPartNumbers', 'get')}>
                    <h2>Add GM Part Numbers Acdelco</h2>
                    <button type="submit">Submit</button>
                </form>

                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/addAll', 'get')}>
                    <h2>Associate images, product descriptions and all parts extra data</h2>
                    <button type="submit">Submit</button>
                </form>
                
                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/createIndexYearMakeModelVehicle', 'get')}>
                    <h2>Create Indexes Main Tables (Year, Make, Model - Vehicle)</h2>
                    <button type="submit">Submit</button>
                </form>
               
                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/createIndexGMPartNumberAcdelcoParts', 'get')}>
                    <h2>Create Indexes Main Tables (GM Part Number - Acdelco Parts)</h2>
                    <button type="submit">Submit</button>
                </form>

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/createIndexCategoryUniversalParts', 'get')}>
                    <h2>Create Indexes Main Tables (Category - Universal Parts)</h2>
                    <button type="submit">Submit</button>
                </form>

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/createIndexPartNumberImage', 'get')}>
                    <h2>Create Indexes Main Tables (Part Number - Image)</h2>
                    <button type="submit">Submit</button>
                </form>
                
                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/createIndexPartNumberAcdelcoPartsExtras', 'get')}>
                    <h2>Create Indexes Main Tables (Part Number - Acdelco Parts Extras)</h2>
                    <button type="submit">Submit</button>
                </form>
                
                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/createIndexExtrasParts', 'get')}>
                    <h2>Create Indexes Extra Parts</h2>
                    <button type="submit">Submit</button>
                </form>
                
                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/createIndexJoinTables', 'get')}>
                    <h2>Create Indexes Join Tables</h2>
                    <button type="submit">Submit</button>
                </form>
                
                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/createIndexJoinTablesUniversalParts', 'get')}>
                    <h2>Create Indexes Join Tables Universal Parts</h2>
                    <button type="submit">Submit</button>
                </form>
                
                <hr />

                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/createIndexMaterializedViews', 'get')}>
                    <h2>Create Indexes Materialized Views</h2>
                    <button type="submit">Submit</button>
                </form>

                <hr />


                <form style={formStyle} onSubmit={event => this.onFormSubmit(event, '/createMaterializedViewsCategoriesAuxiliar', 'get')}>
                    <h2>Create Materialized Views Categories Auxiliar</h2>
                    <button type="submit">Submit</button>
                </form>

                <hr />

            </div>
        )
    }
};

export default UploadData;