import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Checkbox, CheckboxGroup } from 'react-checkbox-group';
import { Scrollbars } from 'react-custom-scrollbars';
import AnimateHeight from 'react-animate-height';
import { relative } from 'path';

const filtersStyle = {
	width: 220
}

const checkboxStyle = {
	width: 16,
	height: 16,
	verticalAlign: 'top',
	horizontalAlign: 'middle',
	margin: '3px 3px 3px 3px',
	backgroundColor: '#ff3300',
    color: '#ff3300',
	position: 'relative',
	opacity: '1'
}


class FilterResults extends Component {

	constructor() {
		super();
		this.state = {
			popUpIsOpen: false,
			subModels: [],
			engines: [],
			partNames: [],
			filterOn: false,
			showFilterResults: true,
			height: 'auto'
		}
	}

	componentDidMount() {
        this.checkScreenSize();
    }
    
    checkScreenSize() {
		let mobileScreenSize = (window.innerWidth <= 1000);
        if (mobileScreenSize) {
			this.setState({
				showFilterResults: false
			});
        }else{
            this.setState({
                showFilterResults: true
            });
        }
    }

	componentWillMount() {
		// add event listener for clicks
		document.addEventListener('click', this.handleClick, false);
	}

	componentWillUnmount() {
		// remove the listener when the component is destroyed
		document.removeEventListener('click', this.handleClick, false);
	}

	handleClick = (e) => {
		// check whether the element clicked upon is in FilterResults component - if not, then call the close logic
		if (this.node) {
			if (!ReactDOM.findDOMNode(this).contains(e.target) && !this.node.contains(e.target)) {
				this.setState({ popUpIsOpen: false });
			}
		}
	}

	clickPopUp = () => {
		if (this.state.popUpIsOpen) {
			this.setState({ popUpIsOpen: false });
		} else {
			this.setState({ popUpIsOpen: true });
		}
	}

	subModelChanged = (subModels) => {
		this.handleFilter(subModels, this.state.engines, this.state.partNames);
	}

	engineChanged = (engines) => {
		this.handleFilter(this.state.subModels, engines, this.state.partNames);
	}

	partNameChanged = (partNames) => {
		this.handleFilter(this.state.subModels, this.state.engines, partNames);
	}


	handleFilter(subModels, engines, partNames) {
		let listParts = []

		if (subModels.length == 0 && engines.length == 0 && partNames.length == 0) {
			listParts = [...this.props.acdelcos];
			if (this.state.filterOn) {
				this.setState({
					filterOn: !this.state.filterOn
				})
			}
		} else {
			this.props.acdelcos.map((acdelco, index) => {
				if (subModels.length != 0 && engines.length != 0 && partNames.length != 0) {
					if (subModels.includes(acdelco.acdelco.subModel) && engines.includes(acdelco.acdelco.notesEngine) && partNames.includes(acdelco.acdelco.partName)) {
						listParts.push(acdelco);
					}
				}
				else if (subModels.length == 0 && engines.length != 0 && partNames.length != 0) {
					if (engines.includes(acdelco.acdelco.notesEngine) && partNames.includes(acdelco.acdelco.partName)) {
						listParts.push(acdelco);
					}
				}
				else if (subModels.length != 0 && engines.length == 0 && partNames.length != 0) {
					if (subModels.includes(acdelco.acdelco.subModel) && partNames.includes(acdelco.acdelco.partName)) {
						listParts.push(acdelco);
					}
				}
				else if (subModels.length != 0 && engines.length != 0 && partNames.length == 0) {
					if (subModels.includes(acdelco.acdelco.subModel) && engines.includes(acdelco.acdelco.notesEngine)) {
						listParts.push(acdelco);
					}
				}
				else if (subModels.length != 0 && engines.length == 0 && partNames.length == 0) {
					if (subModels.includes(acdelco.acdelco.subModel)) {
						listParts.push(acdelco);
					}
				}
				else if (subModels.length == 0 && engines.length != 0 && partNames.length == 0) {
					if (engines.includes(acdelco.acdelco.notesEngine)) {
						listParts.push(acdelco);
					}
				}
				else if (subModels.length == 0 && engines.length == 0 && partNames.length != 0) {
					if (partNames.includes(acdelco.acdelco.partName)) {
						listParts.push(acdelco);
					}
				}
			})
			if (!this.state.filterOn) {
				this.setState({
					filterOn: !this.state.filterOn
				})
			}
		}

		const orderedPartsList = listParts.sort(this.getOrderedListOfParts)
		this.props.handleFilterResults(this.props.acdelcos, orderedPartsList);

		this.setState({
			subModels: subModels,
			engines: engines,
			partNames: partNames,
			listParts: orderedPartsList
		})
	}

	getOrderedListOfParts(part1, part2) {
		if (part1.partNumber < part2.partNumber) {
			return - 1
		} else if (part1.partNumber > part2.partNumber) {
			return 1
		}
		return 0
	}

	handleFilterResults = () => {
		let height;
		const { showFilterResults } = this.state
		
		if (!showFilterResults) {
			height = 'auto';
		} else {
			height = '10px';
		}
		this.setState({
			showFilterResults: !showFilterResults,
			height: height
		});
	}


	render() {

		//Get the list of subModels for each part
		let subModels = []
		if (this.props.acdelcos !== undefined) {
			this.props.acdelcos.map((acdelco, index) => {
				if (!subModels.includes(acdelco.acdelco.subModel) && acdelco.acdelco.subModel != "") {
					subModels.push(acdelco.acdelco.subModel)
				}
			})
		}
		subModels.sort()

		//Get the list of sub models for the sub model filter
		let subModelsForFilter = [];
		subModels.map((subModel, index) => {
			subModelsForFilter.push([<Checkbox value={subModel} style={checkboxStyle} />, <label>{subModel}</label>, <br></br>]);
		})

		//Get the list of engines for each part
		let engines = []
		if (this.props.acdelcos !== undefined) {
			this.props.acdelcos.map((acdelco, index) => {
				if (!engines.includes(acdelco.acdelco.notesEngine) && acdelco.acdelco.notesEngine != "") {
					engines.push(acdelco.acdelco.notesEngine)
				}
			})
		}
		engines.sort()

		//Get the list of engines for the engine filter
		let enginesForFilter = [];
		engines.map((engine, index) => {
			enginesForFilter.push([<Checkbox value={engine} style={checkboxStyle} />, <label>{engine}</label>, <br></br>]);
		})

		//Get the list of partNames for each part
		let partNames = []
		if (this.props.acdelcos !== undefined) {
			this.props.acdelcos.map((acdelco, index) => {
				if (!partNames.includes(acdelco.acdelco.partName) && acdelco.acdelco.partName != "") {
					partNames.push(acdelco.acdelco.partName)
				}
			})
		}
		partNames.sort()

		//Get the list of part names for the part name filter
		let partNamesForFilter = [];
		partNames.map((partName, index) => {
			partNamesForFilter.push([<Checkbox value={partName} style={checkboxStyle} />, <label>{partName}</label>, <br></br>]);
		})

		const subModelsFilter = subModelsForFilter.length > 0 ?
			<div className="filter-checkbox">
				<h3 className="title-filter-small">Version/Sub-model:</h3>
				<Scrollbars
					style={filtersStyle}
					autoHeight={true}
					autoHeightMax={300}>
					<CheckboxGroup name="subModels" value={this.state.subModels} onChange={this.subModelChanged}>
						{subModelsForFilter}
					</CheckboxGroup>
				</Scrollbars>
			</div>
			: "";

		const enginesFilter = enginesForFilter.length > 0 ?
			<div className="filter-checkbox">
				<h3 className="title-filter-small">Engine:</h3>
				<Scrollbars
					style={filtersStyle}
					autoHeight={true}
					autoHeightMax={300}>
					<CheckboxGroup name="engines" value={this.state.engines} onChange={this.engineChanged}>
						{enginesForFilter}
					</CheckboxGroup>
				</Scrollbars>
			</div>
			: "";

		const partNamesFilter = partNamesForFilter.length > 0 ?
			<div className="filter-checkbox">
				<h3 className="title-filter-small">Part Name:</h3>
				<Scrollbars
					style={filtersStyle}
					autoHeight={true}
					autoHeightMax={300}>
					<CheckboxGroup name="partNames" value={this.state.partNames} onChange={this.partNameChanged}>
						{partNamesForFilter}
					</CheckboxGroup>
				</Scrollbars>
			</div>
			: "";

		const filterPopUp = <div
			ref={node => this.node = node}
			className="filter-results__pop-up"
		>
			{ /*<button className="filter-results__close-button" onClick={() => this.clickPopUp()}>X</button>*/ }
			{subModelsFilter}
			<br />
			{enginesFilter}
			<br />
			{partNamesFilter}
		</div>

		//const filterLabel = this.state.filterOn ? '+ Filter (ON)' : '+ Filter (OFF)'

		return (

			<>
				{ /*<button className="filter-results__button" onClick={() => this.clickPopUp()}>{filterLabel}</button>
				{this.state.popUpIsOpen ? filterPopUp : null} */}
				<div className="filter-results">
					<div className="filter-results__title">
						<h3 onClick={() => this.handleFilterResults()}>Filter Results<h4>&nbsp;(Show/Hide)</h4></h3>
					</div>
					<AnimateHeight duration={700} height={this.state.height}>
						{this.state.showFilterResults ?
							filterPopUp
						:null}
					</AnimateHeight>
				</div>
			</>
		)

	}
}

export default FilterResults;