import React, { Component } from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';

import Header from './components/header/header';
import Footer from './components/footer/footer';

import Index from './views/index';
import CategoriesPage from './views/categories-page';
import SearchResults from './views/search-results';
import HealthCheck from './views/health-check';
import ShopList from './views/shop-list';
import UniversalPage from './views/universal-page';
import Loader from './components/common/loader';
import UploadData from './views/upload-data';
import CognitoReactSignInForm from './views/CognitoReactSignInForm';
import AdminPage from './views/admin-page';
import AdminCart from './views/admin-cart';
import Analytics from './views/analytics';
import PartsOrdered from './views/parts-ordered';
import Amplify from 'aws-amplify';
import AmplifyConfig from './amplify-config';
import PrivateRoute from './components/auth/private-route';
import UnderMaintenance from './components/UnderMaintenance';


Amplify.configure(AmplifyConfig);


class App extends Component {
	render() {
	return (
		<Router>
			<div className="wrapper">
				<div className="wrapper__inner">
					<Route path="/" component={UnderMaintenance} />
				</div>
				
				<Footer />
			</div>
	  </Router>
	);
  }
}

export default App;
