import React, { Component } from 'react';

// import BlankButton from '../common/blank-button'
import CategoryButton from '../common/category-button'

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import Loader from '../common/loader';

import { Checkbox, CheckboxGroup } from 'react-checkbox-group';

import categories from "../../assets/styles/categories.css";
import AnimateHeight from 'react-animate-height';
import { throws } from 'assert';

class Categories extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			activeClickedItems: [-1],
			showSelectCategory: true,
			height: 'auto'
		}
		this.handler = this.handler.bind(this)
	}

	componentDidMount() {
        this.checkScreenSize();
	}

	componentDidUpdate(prevProps){
		if(prevProps.selectedSubCategory == null && this.props.selectedSubCategory !== null){
			this.checkScreenSize();
		}
	}
    
    checkScreenSize() {
		let mobileScreenSize = (window.innerWidth <= 1000);
        if (mobileScreenSize) {
			if(this.props.selectedSubCategory  !== null){
				this.setState({
					showSelectCategory: false
				});
			}
        }else{
            this.setState({
                showSelectCategory: true
            });
        }
    }

	componentWillMount() {
		if (localStorage['subCategory'] !== undefined) {
			this.props.handleFilterResults();
		}
	}

	handler(activeClickedItems) {
		this.setState({
			activeClickedItems: activeClickedItems
		})
	}

	handleSelectCategory = () => {
		let height;
		const { showSelectCategory } = this.state

		if (!showSelectCategory) {
			height = 'auto';
		} else {
			height = '20px';
		}
		this.setState({
			showSelectCategory: !showSelectCategory,
			height: height
		});
	}

	render() {

		let selectedCategoryHandler = this.props.selectedCategoryHandler
		const { isLoading } = this.state;

		if (isLoading) {
			return (
				<Loader />
			)
		}

		return (
			<div className="categories">
				<div className="categories__head">
					<h3 className="categories__title" onClick={() => this.handleSelectCategory()}>Select Category <h4 className="categories__show-hide">&nbsp;(Show/Hide)</h4></h3>
				</div>

				<ul className="accordion-menu categories-list">
					{
						this.props.categories.map((category, index) => {
							return (
								<AnimateHeight duration={700} height={this.state.height}>
									{this.state.showSelectCategory ?
										<CategoryButton
											key={index}
											handleCategoryClick={function () {
												selectedCategoryHandler(category);
											}}
											handler={this.handler}
											handleFilterResults={this.props.handleFilterResults}
											selectedSubCategoryHandler={this.props.selectedSubCategoryHandler}
											category={this.props.categories[index]}
											categoryIndex={index}
											subCategories={this.props.subCategories}
											activeClickedItems={this.state.activeClickedItems}
											selectedCategory={this.props.selectedCategory}
											selectedSubCategory={this.props.selectedSubCategory}
										>{category.category || category}</CategoryButton>
										: null}
								</AnimateHeight>
							)
						})
					}
				</ul>
			</div>
		)
	}
}

export default Categories;
