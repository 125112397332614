import React, { Component } from 'react';

class Welcome extends Component {
	render() {
		return (
			<div className="welcome">
				<div className="shell">
					<h2 className="welcome__title">
						<em>{this.props.title}</em>
					</h2>

					<div className="welcome__entry">
						<p>{this.props.entry1}</p>
						<p>{this.props.entry2}</p>
						<p>{this.props.entry3}</p>
					</div>
				</div>
			</div>
		)
	}
}

export default Welcome;