import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

class Dropdown extends React.Component {
  constructor() {
    super();

    this.state = {
      displayMenu: false,
      selectedItem: undefined
    };

    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  }

  componentDidMount() {
    this.setState({ selectedItem: this.props.items[0] });
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }

  render() {
    let { displayMenu } = this.state;
    return (
      <div className="part-number-dropdown" style={{}}>
        <button>
          <div className="button-select" onClick={this.showDropdownMenu}>
            {this.state.selectedItem}
            {displayMenu ? (
              <FontAwesomeIcon
                style={{ marginLeft: 7 }}
                size={40}
                color={"blue"}
                icon={faSortUp}
              />
            ) : (
              <FontAwesomeIcon
                style={{ marginLeft: 7 }}
                color={"blue"}
                icon={faSortDown}
                size={40}
              />
            )}
          </div>
          <div>
            {this.state.displayMenu ? (
              <ul>
                {this.props.items &&
                  this.props.items.length > 0 &&
                  this.props.items.map(item => (
                    <li onClick={() => this.setState({ selectedItem: item })}>
                      <div className="item">{item}</div>
                    </li>
                  ))}
              </ul>
            ) : null}
          </div>
        </button>
      </div>
    );
  }
}

export default Dropdown;