import React, { Component } from "react";
import NumericInput from "react-numeric-input";
import Recaptcha from "react-google-recaptcha";
import Modal from "react-modal";
import { Textbox, Select, Textarea, Checkbox } from "react-inputs-validation";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import "../assets/fonts/Amiri-Regular-normal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateQuantity } from "../store/state/item-shoplist/actions";
import { SHOPPING_LIST_API } from "../endpoints/endpoints";
const recaptchaRef = React.createRef();

const customStyles = {
  overlay: {
    zIndex: "100"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "100",
    border: 0,
    padding: 0,
    overflow: "hidden"
  }
};

class ShopList extends Component {
  constructor() {
    super();
    this.handleMessage = this.handleMessage.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.checkItem = this.checkItem.bind(this);
    this.goBack = this.goBack.bind(this);
    this.state = {
      items: localStorage["items"],
      name: "",
      country: "",
      city: "",
      telephone: "",
      email: "",
      remarks: "",
      message: "",
      emailVerified: false,
      captchaVerified: false,
      messageIsOpen: false,
      isChecked: false
    };
  }

  componentDidMount() {
    this.changeTotal();
  }

  removeItem(id) {
    let localStorageAux = [];
    let size = 0;
    if (this.state.items) {
      for (let item of JSON.parse(this.state.items)) {
        if (item.id !== id) {
          localStorageAux.push(item);
          size++;
        }
      }
      if (size === 0) {
        localStorage.removeItem("items");
        this.setState({
          items: localStorage["items"]
        });
      } else {
        localStorage["items"] = JSON.stringify(localStorageAux);
        this.setState({
          items: JSON.stringify(localStorageAux)
        });
      }
    }
    this.changeTotal();
  }

  checkItem() {
    if (this.state.items) {
      return JSON.parse(this.state.items).map(item => {
        return (
          <tr>
            <td>
              {item.partName}
              <br />
              {item.year && item.make && item.model && `${item.year}/${item.make}/${item.model}`}
            </td>
            <td>
              {item.partNumber}
              <br />  {/*Simplify code below by fixing data on backend */}
              {item.oems === undefined || item.oems.length === 0 ? 
                item.gmPartNumber :
                item.oems[0].oem !== undefined ? 
                item.oems[0].oem : 
                item.oems[0]}
            </td>
            <td>
              <NumericInput
                className="quantity__control"
                value={item.quantity}
                onChange={event => {
                  this.changeQuantity(item.id, event);
                }}
                min={1}
                max={99}
                mobile={this.checkScreenSize()}
              />
            </td>
            <td>
              <a
                className="removeItem"
                onClick={() => this.removeItem(item.id)}
              >
                &#10006;
              </a>
            </td>
          </tr>
        );
      });
    }
  }

  changeQuantity(id, quantity) {
    let localStorageAux = [];
    if (localStorage["items"]) {
      for (let item of JSON.parse(localStorage["items"])) {
        if (item.id !== id) {
          localStorageAux.push(item);
        } else {
          item.quantity = quantity;
          localStorageAux.push(item);
        }
      }
      localStorage["items"] = JSON.stringify(localStorageAux);
      this.setState({
        items: JSON.stringify(localStorageAux)
      });
    }
    this.changeTotal();
  }

  changeTotal() {
    let quantity = 0;
    if (localStorage["items"]) {
      for (let item of JSON.parse(localStorage["items"])) {
        quantity = quantity + item.quantity;
      }
    } else {
      quantity = 0;
    }
    this.props.updateQuantity(quantity);
  }

  generateTable(cartId) {
    const columns = [
      { title: "ORDER ID ", key: "orderId" },
      { title: "PART NAME ", key: "partName" },
      { title: "YEAR/MAKE/MODEL ", key: "yearMakeModel" },
      { title: "PART NUMBER", key: "partNumber" },
      { title: "GM PART NUMBER", key: "gmPartNumber" },
      { title: "QTY", key: "quantity" }
    ];
    const data = [];
    if (this.state.items) {
      for (let item of JSON.parse(this.state.items)) {
        data.push({
          orderId: cartId,
          partName: `${item.partName} `,
          yearMakeModel:
            item.year &&
            item.make &&
            item.model &&
            `${item.year}/${item.make}/${item.model} `,
          partNumber: item.partNumber,
          gmPartNumber: item.oems !== undefined && item.oems.length > 0 ? item.oems[0].oem : item.gmPartNumber,
          quantity: item.quantity
        });
      }
    }
    const doc = new jsPDF("p", "pt");
    doc.autoTable(columns, data, {
      styles: {
        textColor: 0,
        lineColor: 0,
        lineWidth: 1
      },
      theme: "plain"
    });
    let finalY = doc.previousAutoTable.finalY;
    doc.setFont("Amiri-Regular");
    doc.setFontSize(12);
    doc.text("Name: " + this.state.name, 40, finalY + 40);
    doc.text("Country: " + this.state.country, 40, finalY + 60);
    doc.text("City: " + this.state.city, 40, finalY + 80);
    doc.text("Telephone: " + this.state.telephone, 40, finalY + 100);
    doc.text("Email: " + this.state.email, 40, finalY + 120);
    if (this.state.isChecked) {
      doc.text(
        "I wish to receive any marketing information or have my information shared with third \nparties for purposes of providing me with marketing information",
        40,
        finalY + 150
      );
      doc.text("Remarks: " + this.state.remarks, 40, finalY + 190, {
        align: "justify",
        maxWidth: 500
      });
    } else {
      doc.text("Remarks: " + this.state.remarks, 40, finalY + 140, {
        align: "justify",
        maxWidth: 500
      });
    }

    this.setState({
      captchaVerified: false,
      name: "",
      country: "",
      city: "",
      telephone: "",
      email: "",
      emailVerified: false,
      remarks: "",
      isChecked: false
    });
    recaptchaRef.current.reset();

    return doc;
  }

  sendEmail = (orderId) => {
    const email = this.state.email;
    const doc = this.generateTable(orderId);
    const pdf = doc.output("blob");
    const url = SHOPPING_LIST_API.defaults.baseURL + "/email";
    const formData = new FormData();
    formData.append("file", pdf);
    formData.append("email", email);
    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };

    SHOPPING_LIST_API.post(url, formData, config).then(response => {
      if (response.status === 200) {
        this.setState({
          message: (
            <div>
              <h3>THANK YOU</h3>
              <span>
                We've successfully received your inquiry and we will be
                <br />
                in contact with you shortly.
              </span>
              <br />
              <span>Thank you for choosing ACDelco.</span>
              <br />
            </div>
          )
        });
      } else {
        this.setState({
          message: (
            <div>
              <h3>ERROR</h3>
              <span>An error occurred while trying to send the email.</span>
            </div>
          )
        });
      }
      this.handleMessage();
    });
  };

  saveCart = (isGeneratePDF) => {
    const url = SHOPPING_LIST_API.defaults.baseURL + "/carts";
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("country", this.state.country);
    formData.append("city", this.state.city);
    formData.append("telephone", this.state.telephone);
    formData.append("email", this.state.email);
    formData.append("remarks", this.state.remarks);
    formData.append("cartItems", this.state.items);
    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };

    SHOPPING_LIST_API.post(url, formData, config).then(response => {
      if (response.status === 200) {
        const cartId = response.data.contents[0].cart.id; 
        if (isGeneratePDF) {
          const doc = this.generateTable(cartId);
          doc.save("shopping_list.pdf");
          this.setState({
            message: (
              <div>
                <h3>THANK YOU</h3>
                <span>
                  We've successfully received your inquiry and we will be
                  <br />
                  in contact with you shortly.
                </span>
                <br />
                <span>Thank you for choosing ACDelco.</span>
                <br />
              </div>
            )
          });
        } else {
          this.sendEmail(cartId);
        }
      } else {
        this.setState({
          message: (
            <div>
              <h3>ERROR</h3>
              <span>An error occurred while trying to send the email.</span>
            </div>
          )
        });
      }
      if (isGeneratePDF) {
        this.handleMessage();
      }
    });
  }

  handleMessage() {
    this.setState({ messageIsOpen: !this.state.messageIsOpen });
  }

  goBack() {
    this.props.history.goBack();
  }

  invokeUrl() {
    window.open(
      "https://www.acdelcoarabia.com/locate-service-auto-parts/locate-auto-parts-store.html",
      "_blank"
    );
  }

  checkForm() {
    var message = "";
    if (!this.state.items) {
      message = "Add at least one item to shop list";
    }

    if (this.state.name == "") {
      if (message !== "") {
        message += "\n";
      }
      message += "Name required";
    }
    if (this.state.country == "") {
      if (message !== "") {
        message += "\n";
      }
      message += "Country required";
    }
    if (this.state.city == "") {
      if (message !== "") {
        message += "\n";
      }
      message += "City required";
    }
    if (this.state.telephone == "") {
      if (message !== "") {
        message += "\n";
      }
      message += "Telephone required";
    }
    if (this.state.email == "") {
      if (message !== "") {
        message += "\n";
      }
      message += "Email required";
    } else if (!this.state.emailVerified) {
      if (message !== "") {
        message += "\n";
      }
      message += "Valid email required";
    }
    // if (!this.state.isChecked) {
    //     if (message !== "") {
    //         message += "\n"
    //     }
    //     message += "Checkbox required"
    // }
    if (
      !this.state.items ||
      this.state.name == "" ||
      this.state.country == "" ||
      this.state.city == "" ||
      this.state.telephone == "" ||
      this.state.email == "" ||
      !this.state.emailVerified
    ) {
      this.setState({
        message: message
      });
      // if (!this.state.isChecked)
      //     document.getElementsByClassName("react-inputs-validation__checkbox__wrapper___1c1rD false false false false")[0].focus();
      if (this.state.email == "" || !this.state.emailVerified)
        document.getElementById("email").focus();
      if (this.state.telephone == "")
        document.getElementById("telephone").focus();
      if (this.state.city == "") document.getElementById("city").focus();
      if (this.state.country == "")
        document
          .getElementsByClassName(
            "react-inputs-validation__select__wrapper___1B4OH false false false;"
          )[0]
          .focus();
      if (this.state.name == "") document.getElementById("name").focus();
      this.handleMessage();
      return false;
    }
    return true;
  }

  checkScreenSize() {
    let mobileScreenSize = window.innerWidth <= 1000;
    return mobileScreenSize;
  }

  render() {
    const modal_message =
      this.state.src !== null ? (
        <Modal
          ariaHideApp={false}
          isOpen={this.state.messageIsOpen}
          shouldFocusAfterRender={true}
          style={customStyles}
          onRequestClose={this.handleMessage}
          shouldCloseOnOverlayClick={true}
        >
          <div className="message_box">
            <span>{this.state.message}</span>
            <button className="btn--block" onClick={() => this.handleMessage()}>
              CLOSE
            </button>
          </div>
        </Modal>
      ) : (
          ""
        );

    const { name, country, city, telephone, email } = this.state;

    return (
      <div className="shoplist__main">
        <h3>SHOPPING LIST</h3>

        <div className="table">
          <table id="tableId">
            <tr className="header_line">
              <th className="partName">
                PART NAME <br />
                YEAR/MAKE/MODEL
              </th>
              <th>
                PART #<br />
                GM PART #
              </th>
              <th>QTY</th>
              <th>REMOVE</th>
            </tr>
            {this.checkItem()}
          </table>
        </div>

        <h3>
          Please fill your details below and your nearest ACDelco distributor
          will get back to you shortly.
        </h3>

        <div className="shoplist__form">
          <form>
            <div className="container">
              <div className="form__input">
                <div className="form__item">
                  <strong name="name">Name:</strong>
                  <div className="item__input">
                    <Textbox
                      tabIndex="0"
                      id="name"
                      name="Name"
                      onChange={name => {
                        this.setState({ name });
                      }}
                      value={name}
                      onBlur={e => { }}
                      validationOption={{
                        name: "Name",
                        check: true,
                        required: true
                      }}
                    />
                  </div>
                </div>
                <div className="form__item">
                  <strong name="country">Country:</strong>
                  <div className="item__input">
                    <Select
                      tabIndex="1"
                      id="country"
                      name="country"
                      value={country}
                      optionList={[
                        { id: "", name: "Please Select a country" },
                        { id: "Saudi Arabia", name: "Saudi Arabia" },
                        {
                          id: "United Arab Emirates",
                          name: "United Arab Emirates"
                        },
                        { id: "Kuwait", name: "Kuwait" },
                        { id: "Qatar", name: "Qatar" },
                        { id: "Bahrain", name: "Bahrain" },
                        { id: "Yemen", name: "Yemen" },
                        { id: "Oman", name: "Oman" },
                        { id: "Jordan", name: "Jordan" },
                        { id: "Lebanon", name: "Lebanon" },
                        { id: "Iraq", name: "Iraq" }
                      ]}
                      onChange={country => {
                        this.setState({ country });
                      }}
                      onBlur={e => { }}
                      validationOption={{
                        name: "country",
                        check: true,
                        required: true
                      }}
                    />
                  </div>
                </div>
                <div className="form__item">
                  <strong name="city">City:</strong>
                  <div className="item__input">
                    <Textbox
                      tabIndex="2"
                      id="city"
                      name="City"
                      onChange={city => {
                        this.setState({ city });
                      }}
                      value={city}
                      onBlur={e => { }}
                      validationOption={{
                        name: "City",
                        check: true,
                        required: true
                      }}
                    />
                  </div>
                </div>
                <div className="form__item">
                  <strong name="telephone">Telephone:</strong>
                  <div className="item__input">
                    <Textbox
                      tabIndex="2"
                      id="telephone"
                      name="Telephone"
                      onChange={telephone => {
                        this.setState({ telephone });
                      }}
                      value={telephone}
                      onBlur={e => { }}
                      validationOption={{
                        name: "Telephone",
                        check: true,
                        required: true
                      }}
                    />
                  </div>
                </div>
                <div className="form__item">
                  <strong name="email">Email:</strong>
                  <div className="item__input">
                    <Textbox
                      tabIndex="3"
                      id="email"
                      name="email"
                      type="text"
                      onChange={email => {
                        this.setState({ email });
                        if (email === "") {
                          this.setState({ emailVerified: false });
                        }
                      }}
                      value={email}
                      onBlur={e => { }}
                      validationOption={{
                        name: "Email",
                        check: true,
                        required: true,
                        customFunc: email => {
                          const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                          if (reg.test(String(email).toLowerCase())) {
                            this.setState({
                              emailVerified: true
                            });
                            return true;
                          } else {
                            this.setState({
                              emailVerified: false
                            });
                            return "Invalid email address";
                          }
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="form__item">
                  <strong>Remarks:</strong>
                  <div className="item__input">
                    <Textarea
                      tabIndex="4"
                      id="remarks"
                      name="remarks"
                      type="text"
                      value={this.state.remarks}
                      onChange={remarks => {
                        this.setState({ remarks });
                      }}
                    />
                    <div className="checkbox__item">
                      <div className="checkbox__text">
                        <b>Privacy statement & legal disclaimer</b>
                        <br />
                        <span>
                          I acknowledge and understand that my information will be shared with General Motors, 
                          its affiliates, dealer network or other parties who are required by law for compliance, 
                          safety campaigns, government inquiries, or similar legal process. 
                          My information may also be shared for product research and development purposes, 
                          and to manage customer relationships to provide support. 
                          GM’s Privacy Statement addresses how GM handles the personal information shared with us at{" "}
                        </span>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.acdelcoarabia.com/privacypolicy.html"
                          >
                             https://www.acdelcoarabia.com/privacypolicy.html
                          </a> 
                        <br />
                        <br />
                        <Checkbox
                          labelHtml={
                            <div className="checkbox__label">
                              I wish to receive any marketing information or have my information shared 
                              with third parties for purposes of providing me with marketing information
                            </div>
                          }
                          tabIndex="1"
                          id="checkbox"
                          name="checkbox"
                          checked={this.state.isChecked}
                          onChange={isChecked => {
                            this.setState({ isChecked });
                          }}
                          onBlur={e => { }}
                          validationOption={{
                            name: "checkbox",
                            check: true,
                            required: false
                          }}
                        />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shoplist__captcha">
              <Recaptcha
                ref={recaptchaRef}
                //prod
                sitekey="6Lf4OZkUAAAAAPYiEx_YtLIs9foYDwTlSnpHQujB"
                // sitekey='6LdrspgUAAAAABlzVjVLmFH8IFanLKyDTfrd1JC9'
                onChange={() => {
                  this.setState({
                    captchaVerified: true
                  });
                }}
              />
            </div>
            <div className="form__button">
              <button
                className="btn--block"
                disabled={!this.state.captchaVerified}
                onClick={event => {
                  event.preventDefault();
                  if (this.checkForm()) {
                    this.saveCart(true);
                  }
                }}
                block
              >
                SAVE PDF
              </button>

              <button
                className="btn--block"
                disabled={!this.state.captchaVerified}
                onClick={event => {
                  event.preventDefault();
                  if (this.checkForm()) {
                    this.saveCart(false);
                  }
                }}
                block
              >
                SEND INQUIRY
              </button>
            </div>
            {modal_message}
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  itemsQuantity: store.itemShoplist.itemsQuantity
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateQuantity }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopList);
