import React, { Component } from 'react';
import Item from './item';
import ElasticItem from './elastic-item';
import Loader from '../common/loader';
import Pagination from './pagination';
import { ACDELCO_API } from '../../endpoints/endpoints';


class ItemsList extends Component {

	constructor() {
		super();

		this.state = {
			isLoading: false,
			acdelcos: [],
			filteredAcdelcos: [],
			currentAcdelcos: [],
			currentPage: null,
			totalPages: null
		}
	}

	componentDidMount() {
		//Just get the parts if the acdelco parts didn't come from search-results, avoiding an unnecessary query
		if (this.props.searchByPartNumber == undefined) {
			this.getParts()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.filters.subCategory != this.props.filters.subCategory && this.props.searchByPartNumber == undefined) {
			this.getParts()
		}
	}

	getParts() {
		window.scrollTo(0, 0);

		this.setState({
			isLoading: true
		});

		let searchQuery = "";
		for (let filter in this.props.filters) {
			if (this.props.filters.hasOwnProperty(filter)) {
				if (filter == 'category') {
					searchQuery += 'categoryId' + '=' + this.props.filters['subCategory'].categoryId + '&';
				} else if (filter == 'subCategory') {
					searchQuery += 'subCategoryId' + '=' + this.props.filters[filter].subCategoryId + '&';
				} else {
					searchQuery += filter + '=' + this.props.filters[filter].name + '&';
				}
			}
		}

		// const searchQuery = queryString.stringify(this.props.filters);
		const queryKey = this.props.queryKey
		const query = ACDELCO_API.defaults.baseURL + queryKey + '?' + searchQuery;
		ACDELCO_API.get(query.replace(/\\/g,'%5C'))
			.then(response => {
				this.setState({
					acdelcos: response.data.contents,
					filteredAcdelcos: response.data.contents,
					// currentAcdelcos: [],
					isLoading: false
				})
				if (this.props.searchByPartNumber == undefined && !this.props.showFilterResultsButton) {
					this.props.showFilterResults(this.state.acdelcos, this.state.filteredAcdelcos);
				}
			})
	}

	onPageChanged = (data) => {
		window.scrollTo(0, 0);

		let acdelcos;
		if (this.props.searchByPartNumber != undefined) {
			acdelcos = this.props.acdelcos == undefined ? this.state.filteredAcdelcos : this.props.acdelcos;
		} else {
			acdelcos = this.props.filteredAcdelcos == undefined ? this.state.acdelcos : this.props.filteredAcdelcos;
		}

		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		const currentAcdelcos = acdelcos.slice(offset, offset + pageLimit);

		this.setState({ currentPage, currentAcdelcos, totalPages });
	}


	render() {

		const { currentAcdelcos, currentPage, totalPages } = this.state

		const headerClass = 'total__parts';
		// const headerClass = ['total__parts', currentPage ? 'total__parts__border' : ''].join(' ').trim();

		if (this.state.isLoading) {
			return (
				<Loader />
			)
		}

		let items = null
		let acdelcos = null
		let totalAcdelcos = null;

		/*Verify if the items to be shown are from search by vehicle or search by part number. If this.props.searchByPartNumber == undefined, it's
		from search by vehicle. Otherwise, it's from searh by part number*/
		if (this.props.searchByPartNumber == undefined) {

			acdelcos = this.props.filteredAcdelcos != undefined ? this.props.filteredAcdelcos : this.state.filteredAcdelcos
			totalAcdelcos = acdelcos != undefined ? acdelcos.length : 0;
			items = currentAcdelcos.map((acdelco, index) => {
				return (
					<Item
						key={index}
						acdelco={acdelco.acdelco}
					/>
				)
			})
		} else {
			//Verify if there are no acdelcos yet to avoid rendering empty items
			if (this.props.acdelcos != undefined) {
				acdelcos = this.props.acdelcos
				totalAcdelcos = acdelcos != undefined ? acdelcos.length : 0;
				if (this.props.searchByPartNumber) {
					items = currentAcdelcos.map((current, index) => {
						return (
							<div>
								<div className="items-list-search">
									<label className="items-list-vehicle">{current.acdelcoModel}</label>
								</div>
								<ElasticItem
									key={index}
									acdelco={current.acdelco.acdelco == undefined ? [] : current.acdelco.acdelco}
								/>
								<br></br>
								<br></br>
							</div>
						)
					})
				} else {
					items = currentAcdelcos.map((current, index) => {
						return (
							<div>
								<div className="items-list-search">
									<label className="items-list-vehicle">{current.acdelcoModel}</label>
								</div>
								<Item
									key={index}
									acdelco={current.acdelco.acdelco == undefined ? [] : current.acdelco.acdelco}
								/>
								<br></br>
								<br></br>
							</div>
						)
					})
				}
			}
		}

		if (totalAcdelcos === 0) return null;

		return (
			<div className="items">
				<div className="pagination">
					<div className="left">

						<h2 className={headerClass}>
							<strong className="text-secondary">{totalAcdelcos} {totalAcdelcos > 1 ? 'parts' : 'part'}</strong>
						</h2>

						{/* {currentPage && (
							<span className="page__indicator">
								Page <span className="font-weight-bold">{currentPage}</span> / <span className="font-weight-bold">{totalPages}</span>
							</span>
						)} */}

					</div>
					<div className="right">
						<Pagination totalRecords={totalAcdelcos} pageLimit={20} pageNeighbours={1} onPageChanged={this.onPageChanged} currentPage={this.state.currentPage}/>
					</div>
				</div>
				{items}
				<div className="pagination-bottom">
					<div className="left">

						<h2 className={headerClass}>
							<strong className="text-secondary">{totalAcdelcos} {totalAcdelcos > 1 ? 'parts' : 'part'}</strong>
						</h2>

						{/* {currentPage && (
							<span className="page__indicator">
								Page <span className="font-weight-bold">{currentPage}</span> / <span className="font-weight-bold">{totalPages}</span>
							</span>
						)} */}

					</div>
					<div className="right">
						<Pagination totalRecords={totalAcdelcos} pageLimit={20} pageNeighbours={1} onPageChanged={this.onPageChanged} currentPage={this.state.currentPage}/>
					</div>
				</div>
			</div>
		)
	}
}

export default ItemsList;