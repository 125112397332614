import React, { Component } from 'react';

class ArticleSingle extends Component {
	render() {
		return (
			<article className="article-single">
				
				<div className="article__note">
					<p>{this.props.note}</p>
				</div>

			</article>
		)
	}
}

export default ArticleSingle;